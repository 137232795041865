.relatedproducts{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    padding: 10px 0 100px;
    margin: 0px 170px;
    width: 75%;
}
.relatedproducts h1{
    color: #171717;
    font-size: 40px;
    font-weight: 600;
}
.relatedproducts hr{
    width: 200px;
    height: 4px;
    border-radius: 10px;
    background: #5ebef7;
}
.relatedproducts-item{
    margin-top: 50px;
    display: flex;
    gap: 30px;
}

@media(max-width:1280px){
    .relatedproducts{
        height: 70vh;
        margin-bottom: 150px;
    }
    .relatedproducts h1{
        font-size: 35px;
    }
    .relatedproducts hr{
        width: 180px;
        height: 3px;
    }
    .relatedproducts-item{
        margin-top: 40px;
        gap:20px;
    }

}

@media(max-width:1024px){
    .relatedproducts{
        height: 60vh;
        margin-bottom: 50px;
        margin: auto;
    }
    .relatedproducts h1{
        font-size: 30px;
    }
    .relatedproducts hr{
        width: 160px;
        height: 2px;
    }
    .relatedproducts-item{
        margin-top: 20px;
        gap: 15px;
    }
}

@media(max-width:768px){
    .relatedproducts{
        height: 50vh;
        gap:6px;
    }
    .relatedproducts h1{
        font-size: 25px;
    }
    .relatedproducts hr{
        width: 120px;
        height: 1px;
    }
    .relatedproducts-item{
        margin-top: 20px;
        gap: 10px;
    }
}

@media(max-width:500px){
    .relatedproducts{
        height: 80vh;
    }
    .relatedproducts-item{
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap:20px;
    }
    .relatedproducts h1{
        font-size: 30px;
    }
    .relatedproducts hr{
        width: 180px;
        height: 2px;
    }
   
}