.sub-categories{
    width: auto;
    display: flex;
    justify-content: space-between;
    gap:10px;
    align-items: center;
    padding: 10px 200px 10px 200px;
    /* margin: 20px; */
    /* border: 1px solid black;
    border-radius: 75px; */
    margin: auto;
}

.color-red {
    background-color: #FF5733; /* Red */
}

.color-green {
    background-color: #33FF57; /* Green */
}

.color-blue {
    background-color: #3357FF; /* Blue */
}

.color-yellow {
    background-color: #F3FF33; /* Yellow */
}

.color-pink {
    background-color: #FF33FF; /* Pink */
}


.subCategory-but{
    width: 180px;
    height: 35px;
    outline: none;
    border: 1px solid #7a7a7a;
    border-radius: 75px;
    color: black;
    font-size: 20px;
    font-weight: 500;
    background: #5ebef7;
    cursor: pointer;
}

.clicked_subCategory-but{
    width: 180px;
    height: 35px;
    outline: none;
    border-radius: 75px;
    font-size: 20px;
    font-weight: 500;
    cursor: pointer;
    background: white;
    /* color: #47b5f5; */
    border: 1px solid #47b5f5;
}

.subCategory-but:hover{
    background: white;
    color: #47b5f5;
    border: 1px solid #47b5f5;
}

@media (max-width:1580px){
    .sub-categories{
        gap: 20px;
        padding: 10px 200px 10px 200px;
    }
    .subCategory-but{
        font-size: 18px;
    }
       
   
}

@media (max-width:1280px){
    .sub-categories{
        gap: 10px;
        padding: 10px 150px 10px 150px;
    }
    .subCategory-but{
        font-size: 16px;
    }

}

@media (max-width:1024px){
    .sub-categories{
        gap: 5px;
        padding: 10px 120px 10px 120px;
    }
    .subCategory-but{
        font-size: 14px;
    }

}

@media (max-width:768px){
    .sub-categories{
        gap: 5px;
        padding: 10px 60px 10px 60px;
        display: grid;
        grid-template-columns: 0.5fr 0.5fr 0.5fr 0.5fr ;
        direction: rtl;

    }
    .subCategory-but{
        /* width: 50px;
        height: 35px; */
        font-size: 14px;
    }
    .clicked_subCategory-but{
        width: 160px;
        font-size: 16px;
    }

}

@media (max-width:500px){
    .sub-categories{
        /* gap: 5px; */
        padding: 10px 40px 10px 40px;
        /* width: 100px; */
    }

    .subCategory-but{
        width: 60px;
        height: 35px;
        font-size: 14px;
    }

}

@media (max-width:400px){
    .sub-categories{
        /* gap: 5px; */
        padding: 10px 20px 10px 20px;
        /* width: 100px; */
    }

    .subCategory-but{
        width: 60px;
        height: 45px;
        font-size: 10px;
    }

}