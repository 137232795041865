.item{
    padding-top: 80px;
    display: flex;
    flex-direction: column;
    width:320px;
    height: 550px;
    border: 1px solid #5ebef7;
    align-items: center;
    justify-content: center;
    box-shadow: 2px 3px 4px #909090;
    border-radius: 10px;
    background: linear-gradient(45deg, #81c5ec, white 20%);
}

.item img{
    height: 250px;
    object-fit: contain;
}

.item p{
    margin: 20px 5px;
    text-align: right; 
    font-size: 20px;
    font-weight: 600;
    text-align: center;
}


.item-prices{
    display:flex;
    gap:5px;
    text-align: right;
    align-items: center;
    justify-content: center;
    border-top: 1px solid black;
    padding: 20px;
    width: 260px;
} 

.item_price{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    text-align: right;
    padding: 5px;
    height: 50px;
}

.shekel-price-new{
    color: #374151;
    color: red;
    font-size: 40px;
    font-weight: 500;
    
}

.item-price-new {
    color: #374151;
    color: red;
    font-size: 40px;
    font-weight: 500;
}

.shekel-price-old{
    color:black;
    font-size: 20px;
    font-weight: 500;
    text-decoration: line-through;
}

.item-price-old{
    /* color: #8c8c8c; */
    color:black;
    font-size: 20px;
    font-weight: 500;
    text-decoration: line-through;
}

.item:hover{
    transform: scale(1.05);
    transition: 0.6s;
}

.addToCartButton{
    padding: 20px 40px;
    width: 280px;
    height: 50px;
    font-size: 20px;
    font-weight: 600;
    color: black;
    background: yellow;
    border: none;
    border-radius: 30px;
    outline: none;
    cursor: pointer;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}


@media (max-width:1680px){
    .item{
        width:300px;
        height: 550px;
    }
    .item img{
        width: 280px;
        /* height: 300px; */
        object-fit: contain;
    }
}

@media (max-width:1600px){
    .item{
        width:280px;
        height: 550px;
    }
    .item img{
        width: 260px;
        /* height: 300px; */
        object-fit: contain;
    }
    
    .item-prices{
        width: 240px;
    }
}

@media (max-width:1520px){
    .item{
        width:260px;
        height: 550px;
    }
    .item img{
        width: 240px;
        /* height: 300px; */
        object-fit: contain;
    }
    
    .item-prices{
        width: 220px;
    }
}


@media (max-width:1450px){
    .item{
        width:240px;
        height: 520px;
    }
    .item img{
        width: 220px;
        /* height: 300px; */
        object-fit: contain;
    }
    
    .item-prices{
        width: 200px;
    }
}

@media (max-width:1365px){
    .item{
        width:220px;
        height: 500px;
    }
    .item img{
        width: 200px;
        /* height: 300px; */
        object-fit: contain;
    }
    
    .item-prices{
        width: 180px;
    }
}


@media (max-width:1280px){
    .item{
        padding-top: 40px;
        /*display: flex;
        flex-direction: column; */
        width:220px;
        height: 400px;
        /* border: 1px solid #5ebef7;
        align-items: center;
        justify-content: center;
        box-shadow: 2px 3px 4px #909090;
        border-radius: 10px;
        background: linear-gradient(45deg, #81c5ec, white 20%); */
        padding-bottom: 10px;
    }
    
    .item img{
        /* width: 240px; */
        height: 200px;
        /* object-fit: contain; */
    }
    
    .item p{
        /* margin: 6px 5px;
        text-align: right; */
        font-size: 14px;
        /* font-weight: 600; */
    }
    
    
    .item-prices{
        /* display:flex;
        gap:5px;
        text-align: right;
        align-items: center;
        justify-content: center;
        border-top: 1px solid black; */
        /* padding: 80px; */
        padding: 20px;
        width: 180px;
    }
    
    /* .item_price{ */
        /* display: flex;
        align-items: center;
        justify-content: center;
        gap: 4px;
        text-align: right;
      
        padding: 5px;

        height: 50px; */
    /* } */
    
    .shekel-price-new{
        /* color: #374151;
        color: red; */
        font-size: 35px;
        /* font-weight: 500;  */
    }
    
    .item-price-new {
        /* color: #374151;
        color: red; */
        font-size: 35px;
        /* font-weight: 500; */
    }
    
    .shekel-price-old{
        /* color:black; */
        font-size: 15px;
        /* font-weight: 500;
        text-decoration: line-through; */
    }
    
    .item-price-old{
       
        /* color:black; */
        font-size: 15px;
        /* font-weight: 500;
        text-decoration: line-through; */
    }
    
    .item:hover{
        transform: scale(1.03);
        transition: 0.6s;
    }
    
    .addToCartButton{
        /* padding: 20px 40px; */
        width: 200px;
        height: 40px;
        font-size: 18px;
        /*font-weight: 600;
        color: black;
        background: yellow;
        border: none;
        border-radius: 30px;
        outline: none;
        cursor: pointer;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center; */
    }

}
@media (max-width:1024px){
    .item{
        padding-top: 40px;
        /*display: flex;
        flex-direction: column; */
        width:160px;
        height: 340px;
        /* border: 1px solid #5ebef7;
        align-items: center;
        justify-content: center;
        box-shadow: 2px 3px 4px #909090;
        border-radius: 10px;
        background: linear-gradient(45deg, #81c5ec, white 20%); */
        padding-bottom: 20px;
    }
    
    .item img{
        /* width: 240px; */
        height: 160px;
        /* object-fit: contain; */
    }
    
    .item p{
        margin: 6px 3px;
        /*text-align: right; */
        font-size: 13px;
        /* font-weight: 600; */
    }
    
    
    .item-prices{
        /* display:flex;
        gap:5px;
        text-align: right;
        align-items: center;
        justify-content: center;
        border-top: 1px solid black; */
        /* padding: 80px; */
        padding: 20px;
        width: 120px;
    }
    
    /* .item_price{ */
        /* display: flex;
        align-items: center;
        justify-content: center;
        gap: 4px;
        text-align: right;
      
        padding: 5px;

        height: 50px; */
    /* } */
    
    .shekel-price-new{
        /* color: #374151;
        color: red; */
        font-size: 25px;
        /* font-weight: 500;  */
    }
    
    .item-price-new {
        /* color: #374151;
        color: red; */
        font-size: 25px;
        /* font-weight: 500; */
    }
    
    .shekel-price-old{
        /* color:black; */
        font-size: 13px;
        /* font-weight: 500;
        text-decoration: line-through; */
    }
    
    .item-price-old{
       
        /* color:black; */
        font-size: 13px;
        /* font-weight: 500;
        text-decoration: line-through; */
    }
    
    .item:hover{
        transform: scale(1.03);
        transition: 0.6s;
    }
    
    .addToCartButton{
        /* padding: 20px 40px; */
        width: 140px;
        height: 18px;
        font-size: 14px;
        /*font-weight: 600;
        color: black;
        background: yellow;
        border: none;
        border-radius: 30px;
        outline: none;
        cursor: pointer;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center; */
    }
}

@media (max-width:768px){
    .item{
        padding-top: 20px;
        /*display: flex;
        flex-direction: column; */
        width:120px;
        height: 280px;
        /* border: 1px solid #5ebef7;
        align-items: center;
        justify-content: center;
        box-shadow: 2px 3px 4px #909090;
        border-radius: 10px;
        background: linear-gradient(45deg, #81c5ec, white 20%); */
        padding-bottom: 20px;
    }
    
    .item img{
        /* width: 240px; */
        height: 90px;
        /* object-fit: contain; */
    }
    
    .item p{
        margin: 6px 3px;
        /*text-align: right; */
        font-size: 11px;
        /* font-weight: 600; */
    }
    
    
    .item-prices{
        /* display:flex;
        gap:5px;
        text-align: right;
        align-items: center;
        justify-content: center;
        border-top: 1px solid black; */
        /* padding: 80px; */
        padding: 20px;
        width: 80px;
    }
    
    /* .item_price{ */
        /* display: flex;
        align-items: center;
        justify-content: center;
        gap: 4px;
        text-align: right;
      
        padding: 5px;

        height: 50px; */
    /* } */
    
    .shekel-price-new{
        /* color: #374151;
        color: red; */
        font-size: 20px;
        /* font-weight: 500;  */
    }
    
    .item-price-new {
        /* color: #374151;
        color: red; */
        font-size: 20px;
        /* font-weight: 500; */
    }
    
    .shekel-price-old{
        /* color:black; */
        font-size: 12px;
        /* font-weight: 500;
        text-decoration: line-through; */
    }
    
    .item-price-old{
       
        /* color:black; */
        font-size: 12px;
        /* font-weight: 500;
        text-decoration: line-through; */
    }
    
    .item:hover{
        transform: scale(1.02);
        transition: 0.6s;
    }
    
    .addToCartButton{
        /* padding: 20px 40px; */
        width: 100px;
        height: 12px;
        font-size: 14px;
        /*font-weight: 600;
        color: black;
        background: yellow;
        border: none;
        border-radius: 30px;
        outline: none;
        cursor: pointer;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center; */
    }

}


@media (max-width:500px){
    .item{
        padding-top: 40px;
        /*display: flex;
        flex-direction: column; */
        width:180px;
        height: 240px;
        /* border: 1px solid #5ebef7;
        align-items: center;
        justify-content: center;
        box-shadow: 2px 3px 4px #909090;
        border-radius: 10px;
        background: linear-gradient(45deg, #81c5ec, white 20%); */
        padding-bottom: 20px;
    }
    
    .item img{
        /* width: 240px; */
        height: 100px;
        /* object-fit: contain; */
    }
    
    .item p{
        margin: 6px 3px;
        /*text-align: right; */
        font-size: 16px;
        font-size: 14px;
        /* font-weight: 600; */
    }
    
    
    .item-prices{
        /* display:flex;
        gap:5px;
        text-align: right;
        align-items: center;
        justify-content: center;
        border-top: 1px solid black; */
        /* padding: 80px; */
        padding: 0px;
        width: 80px;
    }
    
    /* .item_price{ */
        /* display: flex;
        align-items: center;
        justify-content: center;
        gap: 4px;
        text-align: right;
      
        padding: 5px;

        height: 50px; */
    /* } */
    
    .shekel-price-new{
        /* color: #374151;
        color: red; */
        font-size: 26px;
        /* font-weight: 500;  */
    }
    
    .item-price-new {
        /* color: #374151;
        color: red; */
        font-size: 26px;
        /* font-weight: 500; */
    }
    
    .shekel-price-old{
        /* color:black; */
        font-size: 16px;
        /* font-weight: 500;
        text-decoration: line-through; */
    }
    
    .item-price-old{
       
        /* color:black; */
        font-size: 16px;
        /* font-weight: 500;
        text-decoration: line-through; */
    }
    
    .item:hover{
        transform: scale(1.02);
        transition: 0.6s;
    }
    
    .addToCartButton{
        /* padding: 20px 40px; */
        width: 100px;
        height: 12px;
        font-size: 14px;
        margin: 0px;
        /*font-weight: 600;
        color: black;
        background: yellow;
        border: none;
        border-radius: 30px;
        outline: none;
        cursor: pointer;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center; */
    }

}

