.offers{
    margin: 10px auto;
    width: 97%;
    /* height: 30vh; */
    display: flex;
    /* flex-wrap: wrap; */
    align-items: center;
    justify-content: center;
    /* padding: 0px 140px; */
    margin-bottom: 20px;
    /* background: linear-gradient(180deg, black,#81c5ec 95%); */
    background-image: url('../Assets/sale_homepage_img_9.gif');
    text-align: right;
}


.offers-right{
    /* flex: 1; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 80px;
    height: 320px;
    
}

.offers-right h1{
    color: white;
    font-size: 70px;
    font-weight: 600;
}

.offers-right h2{
    color: #47b5f5;
    font-size: 50px;
    font-weight: 500;
}

.offers-right p{
    color: #47b5f5;
    font-size: 25px;
    font-weight: 600;
    padding-right: 10px;
}

.offers-right button{
    width: 260px;
    height: 50px;
    border-radius: 35px;
    background: #ff4141;
    border: none;
    color: white;
    font-size: 22px;
    font-weight: 500;
    margin-top: 30px;
    cursor: pointer;
    /* margin-left: 360px; */
    letter-spacing: 5px;
}

.offers-right button:hover{
    background: white;
    color:#47b5f5;
    border: 8px solid #47b5f5;
}
.offers-left{
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-left: 100px;
    padding-right: 350px;

} 

.offers-left img{
    width: 280px;
    padding-top: 20px;
}

@media (max-width:1280px){
    /* .offers{ */
        /* margin: 10px auto;
        width: 97%; */
        /* height: 40vh; */
        /* display: flex; */
        /* flex-wrap: wrap; */
        /* align-items: center;
        justify-content: space-between; */
        /* padding: 0px 140px; */
        /* margin-bottom: 20px;
        background: linear-gradient(180deg, black,#81c5ec 95%);
        text-align: right; */
    /* } */
    
    
    .offers-right{
        
        flex: 1;
        display: flex;
        /* flex-direction: column; */
        justify-content: center;
        /* margin-right: 300px; */
    }
    
    .offers-right h1{
        /* color: #47b5f5; */
        font-size: 60px;
        /* font-weight: 600; */
    }
    
    .offers-right h2{
        /* color: #47b5f5; */
        font-size: 40px;
        /* font-weight: 500; */
    }
    
    .offers-right p{
        /* color: black; */
        font-size: 20px;
        /* font-weight: 600;
        padding-right: 10px; */
    }
    
    .offers-right button{
        width: 240px;
        height: 50px;
        /* border-radius: 35px;
        background: #ff4141;
        border: none;
        color: white; */
        font-size: 22px;
        /* font-weight: 500; */
        margin-top: 20px;
        /* cursor: pointer; */
        /* margin-left: 340px; */
        /* letter-spacing: 5px; */
    }
    
    .offers-right button:hover{
        /* background: white;
        color:#47b5f5; */
        border: 6px solid #47b5f5;
    }
    .offers-left{
        /* flex: 1;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-left: 100px; */
        padding-right: 200px;
    
    } 
    
    .offers-left img{
        width: 220px;
        /* padding-top: 20px; */
    }
    
}
@media (max-width:1024px){
    /* .offers{ */
        /* margin: 10px auto;
        width: 97%; */
        /* height: 26vh; */
        /* display: flex; */
        /* flex-wrap: wrap; */
        /* align-items: center;
        justify-content: space-between; */
        /* padding: 0px 140px; */
        /* margin-bottom: 20px;
        background: linear-gradient(180deg, black,#81c5ec 95%);
        text-align: right; */
    /* } */
    
    
    .offers-right{
        /* flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center; */
        margin-right: 0px;
    }
    
    .offers-right h1{
        /* color: #47b5f5; */
        font-size: 50px;
        /* font-weight: 600; */
    }
    
    .offers-right h2{
        /* color: #47b5f5; */
        font-size: 30px;
        /* font-weight: 500; */
    }
    
    .offers-right p{
        /* color: black; */
        font-size: 20px;
        /* font-weight: 600;
        padding-right: 10px; */
    }
    
    .offers-right button{
        width: 220px;
        height: 40px;
        /* border-radius: 35px;
        background: #ff4141;
        border: none;
        color: white; */
        font-size: 20px;
        /* font-weight: 500; */
        margin-top: 20px;
        /* cursor: pointer; */
        /* margin-left: 320px; */
        /* letter-spacing: 5px; */
    }
    
    .offers-right button:hover{
        /* background: white;
        color:#47b5f5; */
        border: 4px solid #47b5f5;
    }
    .offers-left{
        /* flex: 1;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-left: 100px; */
        padding-right: 80px;
    
    } 
    
    .offers-left img{
        width: 200px;
        /* padding-top: 20px; */
    }
}
@media (max-width:768px){
    /* .offers{ */
        /* margin: 10px auto;
        width: 97%; */
        /* height: 24vh; */
        /* display: flex; */
        /* flex-wrap: wrap; */
        /* align-items: center;
        justify-content: space-between; */
        /* padding: 0px 140px; */
        /* margin-bottom: 20px;
        background: linear-gradient(180deg, black,#81c5ec 95%);
        text-align: right; */
    /* } */
    
    
    .offers-right{
        /* flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center; */
        /* width:50%; */
        margin-right: 100px;
    }
    
    .offers-right h1{
        /* color: #47b5f5; */
        font-size: 40px;
        /* font-weight: 600; */
    }
    
    .offers-right h2{
        /* color: #47b5f5; */
        font-size: 20px;
        /* font-weight: 500; */
    }
    
    .offers-right p{
        /* color: black; */
        font-size: 20px;
        /* font-weight: 600;
        padding-right: 10px; */
    }
    
    .offers-right button{
        width: 180px;
        height: 30px;
        /* border-radius: 35px;
        background: #ff4141;
        border: none;
        color: white; */
        font-size: 20px;
        /* font-weight: 500; */
        margin-top: 20px;
        /* cursor: pointer; */
        /* margin-left: 120px; */
        /* letter-spacing: 5px; */
    }
    
    .offers-right button:hover{
        /* background: white;
        color:#47b5f5; */
        border: 4px solid #47b5f5;
    }
    .offers-left{
        /* flex: 1;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-left: 100px; */
        padding-right: 0px;
    
    } 
    
    .offers-left img{
        width: 150px;
        /* padding-top: 20px; */
    }
}
@media (max-width:534px){
    .offers{ 
        margin: 300px auto; 
        margin-bottom: 80px;
    }
    
    
    .offers-right{
        /* flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center; */
        width:50%;
        margin-right: 30px;
        align-items:flex-end;
    }
    
    .offers-right h1{
        /* color: #47b5f5; */
        font-size: 30px;
        /* font-weight: 600; */
    }
    
    .offers-right h2{
        /* color: #47b5f5; */
        font-size: 15px;
        /* font-weight: 500; */
    }
    
    .offers-right p{
        /* color: black; */
        font-size: 15px;
        /* font-weight: 600;*/
        padding-right: 0px;
    }
    
    .offers-right button{
        width: 140px;
        height: 25px;
        /* border-radius: 35px;
        background: #ff4141;
        border: none;
        color: white; */
        font-size: 20px;
        /* font-weight: 500; */
        margin-top: 20px;
        /* cursor: pointer; */
        margin-left: 0px;
        /* letter-spacing: 5px; */
    }
    .offers-right button:hover{
        /* background: white;
        color:#47b5f5; */
        border: 4px solid #47b5f5;
    }
    .offers-left{
        /* flex: 1;
        display: flex;
        align-items: center;*/
        justify-content: flex-start;
        margin-left: 10px;
        padding-right: 0px;
        
    
    } 
    .offers-left img{
        width: 130px;
        /* padding-top: 20px; */
    }
}

