
.forgotPassword-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* direction: rtl; */
    width: 50%;
    gap: 30px;
    margin: 50px auto 150px auto;
    border: 1px solid #5ebef7;
    /* border-radius: 10px; */
    padding: 50px;
    /* background: #5ebef7; */

}

.forgotPassword-form{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.forgotPassword-form p{
    font-size: large;
}

.forgotPassword-form input{
    width: 300px;
    height: 30px;
}

.forgotPassword-form button{
    background: #5ebef7;
    font-size: large;
    cursor: pointer;
    font-weight: 500;
    border: none;
    border-radius: 10px;
    padding: 10px;
}

.forgotPassword-form button:hover{
    background: black;
    font-size: large;
    cursor: pointer;
    color: #5ebef7;
}

@media (max-width:500px){
    .forgotPassword-form input{
        width: 230px;
        height: 30px;
    }

    .forgotPassword-form p{
        font-size: medium;
    }
}