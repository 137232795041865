.popular{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap:10px;
    height: 75vh;
    margin-bottom: 270px;
}

.popular h1{
    color: #171717;
    font-size: 50px;
    font-weight: 600;
}

hr{
    width: 250px;
    height: 4px;
    border-radius: 10px;
    background: #5ebef7;
}

.popular-item{
    margin-top: 50px;
    display: flex;
    gap:30px;
}

@media(max-width:1280px){
    .popular{
        height: 70vh;
        margin-bottom: 250px;
    }
    .popular h1{
        font-size: 40px;
    }
    .popular hr{
        width: 220px;
        height: 3px;
    }
    .popular-item{
        margin-top: 40px;
        gap:20px;
    }

}
@media(max-width:1024px){
    .popular{
        height: 90vh;
        margin-bottom: 50px;
    }
    .popular h1{
        font-size: 30px;
    }
    .popular hr{
        width: 180px;
        height: 2px;
    }
    .popular-item{
        margin-top: 20px;
        gap: 15px;
    }
}
@media(max-width:768px){
    .popular{
        height: 72vh;
        gap:6px;
    }
    .popular h1{
        font-size: 20px;
    }
    .popular hr{
        width: 120px;
        height: 1px;
    }
    .popular-item{
        /* margin-top: 20px; */
        gap: 10px;
    }
}

@media(max-width:500px){
    .popular{
        height: 75vh;
    }
    .popular-item{
        display: grid;
        grid-template-columns: 1fr 1fr;
        /* gap:20px; */
    }
    .popular h1{
        font-size: 30px;
        margin-bottom: 0;
    }
    .popular hr{
        width: 180px;
        height: 2px;
        background: #5ebef7;
    }
   
}
