
.deliveryForm {
    display: flex;
    justify-content: center !important;
    align-items: center;
    width: 100%;
}

.deliveryContainer {
    display: flex;
    flex-direction: column;
    gap: 5%;
    width: 100%; 
    height: 100%;
    border-radius: 10px;
    border: 1px solid rgb(26, 22, 22);
    z-index: 1;
    animation: logInHeight .5s forwards;

}

.deliveryLabelDiv {
    display: flex;
    justify-content: center;
    align-items: center;
}

.deliveryLabel {
    /* font-family: 'Courier New', Courier, monospace !important; */
    margin: 15px !important;
    border-bottom: 2px solid rgb(0, 0, 0);
    text-align: right;
    /* color: #2c9ad1; */
    /* background: black; */
}

.textfieldDelivery {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    width: 100%;
    /* color: #5ebef7; */
}

.textfield {
    width: 70%;
    /* border: 1px solid black; */
    /* padding: 10px; */
    font-Size: 20px;
    text-align: right;
    direction: rtl;
}

label{
    font-size: 22px;
    text-align: right;
    direction: rtl;
    /* font-family: 'Courier New', Courier, monospace !important; */
    font-weight: bold;
}
.formBtn{
    margin-top: 2%;
    padding: 10px;
    cursor: pointer;
    width: 70%;
    background: green;
    text-transform: none;
}


.btnDiv{
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    gap: 15px;
    margin: 15px;
}
.hr{
    width: 70%;
}

.loginsignup{
    width: 100%;
   
    /* background: #5ebef7; */
    /*background-size: cover; 
    background-position: center;
    opacity: 80%; */
    /* background: linear-gradient(180deg, #81c5ec, black 90%); */
    /* padding-top: 100px; */
    
}
.loginsignup-container{
    width: 90%;
    /* max-width: 550px; */
    /* height: 600px; */
    background: white;
    /* background-color: black; */
    margin: auto;
    padding: 40px 60px;
    text-align: right;
    border-radius: 10px;
    box-sizing: border-box;
    border:1px solid black;
}
.loginsignup-container h1{
    /* margin: 15px 0px; */
    text-align: center;
    /* color:white; */
}
.loginsignup-fields{
    display: flex;
    flex-direction: column;
    gap: 25px;
    margin-top: 20px;
    text-align: right;
}
.loginsignup-fields input{
    height: 60px;
    width: 100%;
    padding-left: 20px;
    border: 1px solid #5ebef7;
    border-radius: 10px;
    outline: none;
    color: #5c5c5c;
    font-size: 18px;
    text-align: right;
    box-sizing: border-box;
} 

.date-time{
    display:flex;
    flex-direction: column;
}

.delivery-time{
    display: flex;
    gap: 30px;
    align-items: center;
}

.delivery-time p{
    font-size: x-large;
    font-weight: 600;;
}
.loginsignup-fields textarea{
    height: 60px;
    width: 100%;
    padding-left: 20px;
    border: 1px solid #5ebef7;
    border-radius: 10px;
    outline: none;
    color: #5c5c5c;
    font-size: 18px;
    text-align: right;
    box-sizing: border-box;
} 

.loginsignup-fields input::placeholder{
    text-align: right;
    padding-right: 2px;
}

.loginsignup-container button{
    width: 80%;
    height: 50px;
    /* color: white; */
    /* background: #5ebef7; */
    /* background: green; */
    /* margin-top: 30px; */
    border: none;
    border-radius: 10px;
    font-size: 22px;
    font-weight: 500;
    cursor: pointer;
    /* display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 80px; */

}

.formBtn1{
    margin-top: 2%;
    padding: 10px;
    cursor: pointer;
    width: 70%;
    color:black;
    background: #5ebef7;
}

.loginsignup-login{
    /* width: 100%; */
    margin-top: 10px;
    color: #5c5c5c;
    font-size: 18px;
    font-weight: 500;
    padding-right: 5px;
    /* color:white; */
}

.loginsignup-login span{
    color: #5ebef7;
    font-weight: 600;
}
.loginsignup-agree{
    display: flex;
    justify-content: space-around;
    margin-top: 10px;
    /* gap: 10px; */
    color: #5c5c5c;
    font-size: 18px;
    font-weight: 500;
    margin-left: 160px;
    /* color:white; */
    /* padding-left: 160px; */
}

.first-last-name {
    display:flex;
    justify-content: center;
    gap:10px;
    align-items: center;
}

.date-time{
    display:flex;
    /* justify-content: center; */
    gap:10px;
    align-items: center;
    border: 1px solid #5ebef7;
    border-radius: 10px ;
    padding: 0px 15px;
}

.date-time p {
    font-size: 16px;
    font-weight: 600;
}

.error-message{
    color: red;
    font-size: 24px;;
}

@media (max-width:1280px){
    .loginsignup{
        padding-top: 50px;
    }
    /* .loginsignup-container{
        width: auto;
        max-width: 500px;
    } */
    .loginsignup-container h1{
        margin: 0px 0px;
    }
    .loginsignup-fields{
        gap: 20px;
        margin-top: 20px;
    }
    .loginsignup-fields input{
        height: 55px;
    } 
    
    .loginsignup-fields input::placeholder{
        text-align: right;
        padding-right: 2px;
    }
  
    .loginsignup-agree{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 10px;
        width:100%;
        margin-left: 60px;
        gap:5px;
    }

}


@media (max-width:800px){
    .loginsignup{
        padding-top: 40px;
    }
    .loginsignup-container{
        width: auto;
        max-width: 500px;
    }
    .loginsignup-fields{
        margin-top: 15px;
    }
    .loginsignup-fields input{
        height: 50px;
    } 

    .loginsignup-container button{
        height: 50px;
    }

    .date-time P{
        font-size: 16px;
    }
    .delivery-time{
        display: flex;
        /* flex-direction:column; */
        gap: 5px;
        align-items: center;
        justify-content: center;
    }
    
    .delivery-time p{
        font-size: 14px;
        font-weight: 600;
    }
   
}

@media (max-width:640px){
    .loginsignup-container{
        width: 90%;
    }
    .delivery-time{
        /* flex-direction: column; */
        gap: 0px;
    }
    .loginsignup-agree{
        margin-top: 10px;
        margin-left: 0px;
        gap:5px;
        font-size: 17px;
    }
    .deliveryForm {
        display: flex;
        justify-content: center !important;
        align-items: center;
        width: 100%;
    }
    
    .deliveryContainer {
        display: flex;
        flex-direction: column;
        gap: 5%;
        width: 100%; 
        height: 100%;
        border-radius: 10px;
        border: 1px solid rgb(26, 22, 22);
        z-index: 1;
        animation: logInHeight .5s forwards;
    
    }
    
    .deliveryLabelDiv {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .deliveryLabel {
        /* font-family: 'Courier New', Courier, monospace !important; */
        margin: 15px !important;
        border-bottom: 2px solid rgb(0, 0, 0);
        text-align: right;
        /* color: #2c9ad1; */
        /* background: black; */
    }
    
    .textfieldDelivery {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        text-align: center;
        width: 100%;
        /* color: #5ebef7; */
    }
    
    .textfield {
        width: 70%;
        /* border: 1px solid black; */
        /* padding: 10px; */
        font-Size: 20px;
        text-align: right;
        direction: rtl;
    }
    
    label{
        font-size: 22px;
        text-align: right;
        direction: rtl;
        /* font-family: 'Courier New', Courier, monospace !important; */
        font-weight: bold;
    }
    .formBtn{
        margin-top: 2%;
        padding: 10px;
        cursor: pointer;
        width: 70%;
        background: green;
    }
    .formBtn1{
        margin-top: 2%;
        padding: 10px;
        cursor: pointer;
        width: 70%;
        color:black;
        background: #5ebef7;
    }
    .btnDiv{
        display: flex;
        justify-content: space-around;
        align-items: center;
        flex-direction: column;
        gap: 15px;
        margin: 15px;
    }
    .hr{
        width: 70%;
    }
    
    .loginsignup{
        width: 100%;
       
        /* background: #5ebef7; */
        /*background-size: cover; 
        background-position: center;
        opacity: 80%; */
        /* background: linear-gradient(180deg, #81c5ec, black 90%); */
        /* padding-top: 100px; */
        
    }
    .loginsignup-container{
        width: 90%;
        /* max-width: 550px; */
        /* height: 600px; */
        background: white;
        /* background-color: black; */
        margin: auto;
        padding: 40px 60px;
        text-align: right;
        border-radius: 10px;
        box-sizing: border-box;
        border:1px solid black;
    }
    .loginsignup-container h1{
        /* margin: 15px 0px; */
        text-align: center;
        /* color:white; */
        font-size: 22px;
    }
    .loginsignup-fields{
        display: flex;
        flex-direction: column;
        gap: 25px;
        margin-top: 20px;
        text-align: right;
    }
    .loginsignup-fields label{
        font-size: 18px;
    }

    .date-time p{
        font-size: 14px;
    }
    .first-last-name{
        flex-direction: column;
    }
    .loginsignup-fields input{
        height: 60px;
        width: 100%;
        padding-left: 20px;
        border: 1px solid #5ebef7;
        border-radius: 10px;
        outline: none;
        color: #5c5c5c;
        font-size: 18px;
        text-align: right;
        box-sizing: border-box;
    } 
    
    .date-time{
        display:flex;
        flex-direction: column;
    }
    
    .delivery-time{
        display: flex;
        gap: 30px;
        align-items: center;
    }
    
    .delivery-time p{
        font-size: x-large;
        font-weight: 600;;
    }
    .loginsignup-fields textarea{
        height: 60px;
        width: 100%;
        padding-left: 20px;
        border: 1px solid #5ebef7;
        border-radius: 10px;
        outline: none;
        color: #5c5c5c;
        font-size: 18px;
        text-align: right;
        box-sizing: border-box;
    } 
    
    .loginsignup-fields input::placeholder{
        text-align: right;
        padding-right: 2px;
    }
    
    .loginsignup-container button{
        width: 80%;
        height: 60px;
        /* color: white; */
        /* background: #5ebef7;
        background: green; */
        /* margin-top: 30px; */
        border: none;
        border-radius: 10px;
        font-size: 20px;
        font-weight: 500;
        cursor: pointer;
        /* display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 80px; */
    
    }
    
    .loginsignup-login{
        /* width: 100%; */
        margin-top: 10px;
        color: #5c5c5c;
        font-size: 18px;
        font-weight: 500;
        padding-right: 5px;
        /* color:white; */
    }
    
    .loginsignup-login span{
        color: #5ebef7;
        font-weight: 600;
    }
    .loginsignup-agree{
        display: flex;
        justify-content: space-around;
        margin-top: 10px;
        /* gap: 10px; */
        color: #5c5c5c;
        font-size: 18px;
        font-weight: 500;
        margin-left: 160px;
        /* color:white; */
        /* padding-left: 160px; */
    }
    
    .first-last-name {
        display:flex;
        justify-content: center;
        gap:10px;
        align-items: center;
    }
    
    .date-time{
        display:flex;
        /* justify-content: center; */
        gap:10px;
        align-items: center;
        border: 1px solid #5ebef7;
        border-radius: 10px ;
        padding: 0px 15px;
    }
    
    .date-time p {
        font-size: 15px;
        font-weight: 600;
    }

    .loginsignup-container{
        width: 100%;
        background: white;

        margin: auto;
        padding: 20px 10px;
        
    }
    
}

