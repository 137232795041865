.breadcrum{
    /* display: flex;
    align-items: center;
    gap: 8px;
    color: #5e5e5e;
    font-size: 16px;
    font-weight: 600; */
    /* margin: 60px 170px; */
    margin: 60px 170px ;
    /* text-transform: capitalize; */
    direction: rtl;
    text-align: right;
    /* width:75%; */
}

/* .breadcrum img{
    transform: scaleX(-1);
} */

@media(max-width:1280px){
    .breadcrum{
        /* margin: 30px 150px; */
        margin: 30px auto;
        font-size:14px;
    }
}

@media(max-width:1280px){
    .breadcrum{
        /* margin: 30px 130px; */
        margin: 30px auto;
        font-size: 13px;
    }
}

@media(max-width:768px){
    .breadcrum{
        /* margin: 30px 110px; */
        margin: 40px auto;
        margin-top: 170px;
        font-size: 12px;
    }
    
}

@media(max-width:500px){
    .breadcrum{
      /* width:400px; */
      font-size: 10px;
      margin-top: 170px;
    }
}
