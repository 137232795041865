/* Overlay for the background */
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  /* Popup container */
  .popup {
    /* background-color: #fff; */
    background: linear-gradient(45deg, #81c5ec, white 50%);
    padding: 10px;
    border-radius: 14px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    text-align: center;
    width: 90%;
    max-width: 400px;
    border: 3px solid #5ebef7;
  }
  
  /* Popup title */
  .popup h2 {
    margin-bottom: 15px;
    font-size: 42px;
    color: #5ebef7;
  }
  
  /* Popup message */
  .over-age {
    margin-bottom: 20px;
    font-size: 24px;
    color: #555;
  }
  
  /* Button container */
  .button-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 10px;
    flex-direction: column;
  }
  
  /* Buttons */
  .btn {

    padding: 10px 20px;
    border: none;
    border-radius: 14px;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  .btn.accept {
    font-size: 24px;
    width: 70%;
    background-color: #4caf50;
    color: white;
  }
  
  .btn.accept:hover {
    color: #25a32b;
    background-color: white;
    border: 1px solid #25a32b;
  }
  
  .btn.reject {
    font-size: 20px;
    width: 60%;
    background-color: red;
    color: black;
    /* height: 40px; */
  }
  
  .btn.reject:hover {
    color: #d32f2f;
    background-color: white;
    border: 1px solid #d32f2f;
  }
  
  .warnning{
    margin: 20px auto;
    padding-top: 10px;
    font-size: 18px;
    width: 80%;
    border-top: 1px solid black;
    border-bottom: 1px solid black;;
  }

  .hanayadeem-logo{
    width: 140px;
  }
  