/* Container for the terms and policy section */
.terms-container {
    max-width: 800px; /* Adjusts the maximum width for better readability */
    margin: 50px auto; /* Centers the container */
    padding: 20px; /* Adds padding for spacing */
    background-color: #f9f9f9; /* Light background color for better contrast */
    border-radius: 10px; /* Rounded corners for a modern look */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
    font-family: Arial, sans-serif; /* Ensures compatibility with Hebrew characters */
    color: #333; /* Dark gray text color */
    direction: rtl; /* Right-to-left layout for Hebrew */
    text-align: right; /* Aligns the text to the right */
  }
  
  /* Title styling */
  .terms-container h1 {
    text-align: center; /* Centers the title text */
    color: #444; /* Slightly darker text for the title */
    margin-bottom: 20px; /* Adds spacing below the title */
    font-size: 24px; /* Increases the font size for visibility */
    direction: rtl; /* Ensures the title uses right-to-left alignment */
  }
  
  /* Scrollable content */
  .terms-content {
    max-height: 400px; /* Limits the height for scrollable content */
    overflow-y: auto; /* Enables vertical scrolling for long text */
    line-height: 1.6; /* Increases line height for readability */
    font-size: 16px; /* Standard font size for content */
    padding: 10px; /* Adds padding inside the content box */
    border: 1px solid #ddd; /* Light border for distinction */
    border-radius: 5px; /* Slightly rounded corners */
    background-color: #5ebef7; /* White background for clean readability */
    direction: rtl; /* Ensures the content respects Hebrew alignment */
    text-align: right; /* Right-aligns the content text */
  }
  
  /* Paragraph spacing */
  .terms-content p {
    margin-bottom: 15px; /* Adds spacing between paragraphs */
  }
  
  /* Responsive Design */
  @media (max-width: 600px) {
    .terms-container {
      padding: 15px; /* Reduces padding for smaller screens */
      font-size: 14px; /* Adjusts font size for better fit */
    }
  
    .terms-content {
      font-size: 14px; /* Ensures content remains legible on small screens */
    }
  }
  