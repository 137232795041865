.hero{
    min-height: 40vh;
    background:  linear-gradient(180deg, #5ebef7, #3d7aca22 60%);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 9%;
    /* background-image: url("../Assets/soft_drink1.png"); */
    /* margin-top: 160px; */
}

.hero-right{
    line-height: 1.1;
    display: flex;
    flex-direction: column;
    align-items: right;
    justify-content: right;
}

.hero-right h2{
    color: #090909;
    font-size: 24px;
    text-align: right;
}


@keyframes CoolAnimation {
    from{
        background-color: #ff4141;
    }
    to{
        background-color: #c74e46;
    }
}

.hero-right p{
    color: #171717;
    font-size: 60px;   
    font-weight: 600;
    text-align: right;
}
.hero-hand-icon{
    display: flex;
    align-items: center;
    gap: 20px;
    margin-left: 100px;
}
.hero-hand-icon img{
    width: 70px;
}
.hero-hand-icon p{
    color: #5ebef7;
    font-size: 60px;   
    font-weight: 600;
    text-align: right;
}
.hero-latest-btn{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
    width: 250px;
    height: 60px;
    border-radius: 75px;
    margin-top: 20px;
    background: #ff4141;
    color: white;
    font-size: 28px;
    font-weight: 500;
    cursor: pointer;
    margin-left: 140px;
    animation: CoolAnimation 0.4s infinite alternate;
}
.hero-latest-btn img{
    width: 18px;
}

/* .hero-left img{
    width: 450px;
} */



@media (max-width:1280px){
    .hero-right{
        padding-right: 100px;
    }
    .hero-right h2{
        font-size: 20px;
    }
    .hero-right p{
        font-size: 50px;
    }
    .hero-hand-icon img{
        width: 60px;
    }
    .hero-latest-btn{
        gap: 10px;
        width: 220px;
        margin-top: 15px;
    }
    .hero-left img{
        width: 450px;
    }
}
@media (max-width:1024px){
    .hero-right{
        padding-right: 0px;
    }
    .hero-right h2{
        font-size: 18px;
    }
    .hero-right p{
        font-size: 40px;
    }
    .hero-hand-icon img{
        width: 50px;
    }
    .hero-latest-btn{
        width: 200px;
        height: 40px;
        font-size: 24px;
    }
    .hero-left{
        margin-left: -100px;
    }
    .hero-left img{
        width: 400px;
    }

}
@media (max-width:768px){
    .hero{
        margin-top: 160px;
    }
    
    .hero-right{
        padding-right: 0px;
    }
    .hero-right h2{
        font-size: 16px;
    }
    .hero-right p{
        font-size: 35px;
    }
    .hero-hand-icon img{
        width: 40px;
    }
    .hero-latest-btn{
        width: 140px;
        height: 30px;
        font-size: 20px;
    }
    /* .hero-left{
        margin-left: -100px;
    }
    .hero-left img{
        width: 350px;
    } */

    .hero-left{
        display:none;
    }
}
@media (max-width:500px){
    .hero{
        padding-top: 100px;
        flex-direction: column;
        min-height: 30vh;
    }
    .hero-right{
        padding-right: 50px;
    }
    .hero-right h2{
        font-size: 20px;
    }
    .hero-right p{
        font-size: 40px;
    }
    .hero-hand-icon img{
        width: 40px;
    }
    .hero-latest-btn{
        width: 180px;
        height: 40px;
        font-size: 22px;
    }
    .hero-left{
        display:none;
    }
}