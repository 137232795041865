.footer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* gap: 25px; */
    background: #081923;
    /* padding-top: 20px; */
    /* height: 200px; */
}

.footer-top{
    display: flex;
    height: 120px;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    padding: 40px 0px;
}
.footer hr{
    width: 100%;/* width: 90%; */
    background: white;
}
.footer-logo{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    cursor: pointer;
}
.footer-logo p{
    color: #5ebef7;
    font-size: 36px;
    font-weight: 700;
}

.eng-name{
    border-bottom: 1px solid white;
}
.footer-logo img{
    width: 120px;
}

.contact-us{
    display: flex;
    flex-direction: column;
    font-size: 22px;
    gap: 15px;
    color: #5ebef7;
    /* border-right: 1px solid white;
    padding: 0px 50px 0px 0px; */
}

.phone{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
}

.phone-numbers{
    display: flex;
    flex-direction: column;
    color: white;
}

.mail{
    display: flex;
    align-items: center;
    gap: 10px;
}

.mail p{
    color: white;
}

.footer-links{
    display: flex;
    flex-direction: column-reverse;
    list-style: none;
    gap: 3px;
    color: #5ebef7;
    font-size: 16px;
    border-right: 1px solid white;
    text-align: right;
    padding: 0px 50px 0px 0px;
}
.footer-links li{
    cursor: pointer;
    font-size: 22px;
    font-weight: 500;
    
}

.footer-links li:hover{
    text-decoration: underline;
}

.footer-social-media{
    display: flex;
    flex-direction: column;
    gap: 15px;
    border-right: 1px solid white;
    padding: 0px 50px 0px 0px;
}

.footer-social-media p {
    color: #5ebef7;
    font-size: 22px;
    font-weight: 500;
}

.footer-social-icons{
    display: flex;
    gap: 5px;
    color: #5ebef7;
}


.footer-icons-container{
    padding: 10px;
    /* padding-bottom: 6px; */
    /* background: #fbfbfb;
    border: 1px solid #5ebef7;
    border-radius: 5px; */
}
/* .footer-icons-container img{
    width: 25px;
} */

/* .footer-copyright{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    width: 100%;
    margin-bottom: 30px;
    color: #1a1a1a;
    font-size: 16px;
} */

.footer-icons-container:hover{
    transform: scale(1.2);
    transition: 0.6s;
}

.footer-copyright{
    display:flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(180deg, #81c5ec, black 90%);
    width:100%;
    height: 6.5vh;
}

.footer-copyright p{
    color: white;
    font-size: 18px;
}

.footer-copyright img {
    width: 60px;
}
/* .footer-copyright hr{
    width: 80%;
    border: none;
    border-radius: 10px;
    height: 2px;
    background: #c7c7c7;
} */


@media (max-width:1024px){
    .footer-top{
        display: flex;
        height: 120px;
        justify-content: space-around;
        align-items: center;
        width: 100%;
        padding: 40px 0px;
    }
    .footer-social-icons{
        display: flex;
        gap: 5px;
        color: #5ebef7;
        border-bottom: 1px solid white;
        border-top: 1px solid white;
        padding: 5px;
    }

    .footer-links{
        border: none;
        border-bottom: 1px solid white;
        width: 100%;
        align-items: center;
        padding-bottom: 5px;
        padding-right: 0px;
    }

    .footer-social-media{
        padding-right: 0px;
        border: none;
    }
      
    .footer-top{ 
        flex-direction: column;
        height: 450px;
        gap: 20px;
    }
    .phone{
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
    }

    .contact-us{
        /* border-bottom: 1px solid white; */
        width: 75%;
    }
    .mail{
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
    }

  }

@media (max-width:768px){
    .footer{
        gap: 20px;
        /* padding-top: 15px; */
    }

    .footer-top{
        height: 480px;
    }
    
    .footer-logo{
        align-items: center;
        gap: 15px;
        justify-content: center;
    }
    .footer-logo p{
        font-size: 25px;
    }
    .footer-logo img{
        width: 40px;
    }

    .footer-links{
        display: flex;
        justify-content: center;
        list-style: none;
        gap: 5px;
        font-size: 14px;
        align-items: center;
    }
    .footer-links li{
        font-size: 20px;
        font-weight: 600;
        border:none;
    }
    .footer-social-icons{
        gap: 10px;
    }
    .footer-icons-container{
        padding: 5px;
        padding-bottom: 6px;
        /* background: #fbfbfb;
        border: 1px solid #5ebef7; */
        /* border-radius: 5px; */
    }
    /* .footer-icons-container img{
        width: 25px;
    } */
    
    .footer-copyright{
        margin-top: 50px;
        width:100%;
        height: 6vh;
    }
    
    .footer-copyright p{
        font-size: 14px;
    }
}