.shopcategory-banner{
    display: block;
    /* margin: 5px auto; */
    width:100%;
    /* height: 20vh;
    overflow: hidden; */
    /* margin-top: 160px; */
}

.subCategories_navbar{
    border: 1px solid black;
    border-radius: 75px;
    width: 95%;
    margin: 10px auto;
}


.shopcategory-indexSort{
    display: flex;
    margin: 0px 100px;
    justify-content: space-between;
    align-items: center;
}

.shopcategory-sort{
    padding: 10px 20px;
    border-radius: 40px;
    border: 1px solid #888;
    font-size: 20px;
    margin-top: 10px;
}

.shopcategory-indexSort p span{
    font-weight: 600;
    font-size: 24px;
}

.shopcategory-allProducts{
    margin: 20px 170px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    row-gap: 100px;
    /* direction: rtl; */
}

.shopcategory-loadmore{
    display:flex;
    justify-content: center;
    align-items: center;
    margin: 150px auto;
    width: 233px;
    height: 69px;
    border-radius: 75px;
    background: #ededed;
    color: #787878;
    font-size: 18px;
    font-weight: 500;
}

@media (max-width:1280px){
    
    .shopcategory-indexSort{
        margin: auto;
        width:90%;
        /* justify-content: space-between;
        align-items: center; */
    }
    
    .shopcategory-sort{
        padding: 5px 10px;
        font-size: 16px;
    }
    
    .shopcategory-indexSort p span{
        font-weight: 600;
        font-size: 20px;
    }
    
    .shopcategory-allProducts{
        margin: 10px 130px;
        row-gap: 40px;
    }

    .shopcategory-products .item {
        margin:auto;
    }
    
    .shopcategory-loadmore{
        display:flex;
        justify-content: center;
        align-items: center;
        margin: 100px auto;
        width: 200px;
        height: 60px;
        border-radius: 75px;
        background: #ededed;
        color: #787878;
        font-size: 16px;
        font-weight: 500;
    }
}
@media (max-width:1024px){
     
    .shopcategory-sort{
        padding: 5px 10px;
    }
    
    .shopcategory-allProducts{
        margin: 10px 150px;
    }
}
@media (max-width:768px){

    .shopcategory-banner{
        margin-top: 160px;
    }

    .subCategories_navbar{
        border: 1px solid black;
        border-radius: 40px;
        width: 95%;
        margin: 10px auto;
    }

    .shopcategory-sort{
        padding: 5px 10px;
    }
    
    .shopcategory-allProducts{
        margin: 10px 20px;
    }

    .shopcategory-loadmore{
       
        margin: 60px auto;
        width: 150px;
        height: 40px;
        font-size: 14px;
    }
}
@media (max-width:500px){
    
    .shopcategory-sort{
        padding: 5px 10px;
    }
    
    .shopcategory-allProducts{
       grid-template-columns: 1fr 1fr;
       gap: 20px;
       margin: 10px 20px;
    }

    .shopcategory-loadmore{
       
        margin: 60px auto;
        width: 150px;
        height: 40px;
        font-size: 14px;
    }
}