
.resetPassword-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* direction: rtl; */
    width: 50%;
    gap: 30px;
    margin: 50px auto 150px auto;
    /* border: 1px solid #5ebef7; */
    /* border-radius: 10px; */
    /* padding: 50px; */
    /* background: #5ebef7; */

}

.resetPassword-form{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    border: 1px solid #5ebef7;
    padding: 40px 70px 70px 70px;
    position: relative; 
    
}

.resetPassword-form p{
    font-size: large;
}

.resetPassword-form input{
    width: 300px;
    height: 30px;
}

.resetPassword-form button{
    background: #5ebef7;
    font-size: large;
    cursor: pointer;
    font-weight: 500;
    border: none;
    border-radius: 10px;
    padding: 5px;

    position: absolute; /* Position the button absolutely within the form */
    bottom: 20px; /* Distance from the bottom edge of the form */
    left: 20px;

}

.resetPassword-form button:hover{
    background: black;
    font-size: large;
    cursor: pointer;
    color: #5ebef7;
}

@media (max-width:500px){

    .resetPassword-form{
        width: 80%;
        
    }

    .resetPassword-form input{
        margin-top: 5px;
        width: 200px;
        height: 30px;
    }

    .resetPassword-form button{
        background: #5ebef7;
        font-size: large;
        cursor: pointer;
        font-weight: 500;
        border: none;
        border-radius: 10px;
        padding: 8px;
    
        position: absolute; /* Position the button absolutely within the form */
        bottom: 20px; /* Distance from the bottom edge of the form */
        left: 30px;
    
    }
}

