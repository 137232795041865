.newsletter{
    width: 90%;
    max-width: 1400px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto;
    padding: 50px 8%;
    margin-bottom: 100px;
    background:  linear-gradient(180deg, #5ebef7, #3d7aca22 60%);
    gap: 20px;
}

.newsletter h1{
    color: #454545;
    font-size: 45px;
    font-weight: 600;
}

.newsletter p{
    color: #454545;
    font-size: 22px;
}

.newsletter div{
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: white;
    width: 95%;
    max-width: 650px;
    height: 60px;
    border-radius: 80px;
    border: 1px solid #e3e3e3;
}

.newsletter input{
    flex: 1;
    /* padding-left: 30px; */
    border: none;
    outline: none;
    color: #616161;
    font-family: Poppins;
    font-size: 18px;
    text-align: right;
    margin-right: 15px;
}

.newsletter div button{
    width: 180px;
    height: 60px;
    border-radius: 80px;
    background: black;
    color: white;
    font-size: 18px;
    cursor: pointer;
}

.message{
    background: white;
    font-size: 32px;
    font-weight: 600;
    border: 1px solid black;
    border-radius: 400px;
    padding: 10px;
}

@media(max-width:1280px){
    .newsletter{
        width: 80%;
        margin-bottom: 80px;
        gap: 18px;
    }
    
    .newsletter h1{ 
        font-size: 42px;
    }
    
    .newsletter p{
        font-size: 22px;
    }
    
    .newsletter div{
        width: 70%;
        max-width: 650px;
        height: 50px;
    }
    
    .newsletter input{
        font-size: 18px;
    }
    
    .newsletter div button{
        width: 160px;
        height: 50px;
        font-size: 18px;
    }
}
@media(max-width:1024px){
    .newsletter{
        width: 80%;
        height: 250px;
        margin-bottom: 70px;
        gap: 16px;
    }
    
    .newsletter h1{ 
        font-size: 40px;
    }
    
    .newsletter p{
        font-size: 22px;
    }
    
    .newsletter div{
        width: 70%;
        max-width: 650px;
        height: 40px;
    }
    
    .newsletter input{
        font-size: 18px;
    }
    
    .newsletter div button{
        width: 100px;
        height: 40px;
        font-size: 18px;
    }
}
@media(max-width:768px){
    .newsletter{
        width: 70%;
        height: 200px;
        margin-bottom: 70px;
        gap: 16px;
        padding: 100px 8%;
        
    }
    
    .newsletter h1{ 
        font-size: 30px;
    }
    
    .newsletter p{
        font-size: 18px;
    }
    
    .newsletter div{
        width: 80%;
        max-width: 650px;
        height: 40px;
    }
    
    .newsletter input{
        font-size: 16px;
    }
    
    .newsletter div button{
        width: 100px;
        height: 40px;
        font-size: 16px;
    }
}
@media(max-width:500px){
    .newsletter{
        width: 100%;
        height: 170px;
        margin-bottom: 70px;
        gap: 14px;
    }
    
    .newsletter h1{ 
        font-size: 24px;
    }
    
    .newsletter p{
        font-size: 18px;
        font-weight: 500;
        max-width: 250px;
        text-align: center;
        margin-bottom: 10px;;
    }
    
    .newsletter div{
        width: 85%;
        max-width: 650px;
        height: 35px;
    }
    
    .newsletter input{
        font-size: 14px;
        margin-right: 5px;
        width:100px;
    }
    
    .newsletter div button{
        width: 80px;
        height: 35px;
        font-size: 12px;
    }
}


