.navbar-admin{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 15px 60px;
    box-shadow: 0 1px 3px -2px black;
    margin-bottom: 1px;
    /* background: #5ebef7; */
    font-size: 22px;
    /* color: #5ebef7; */
    gap: 10px
}

h3{
    /* color: #5ebef7; */
    /* background: #5ebef7; */
    padding: 10px;
    border: 1px solid #5ebef7;
    border-radius: 5px;
}
.nav-profile{
    width: 75px;
}

@media(max-width:800px){
    .navbar-admin{
        padding: 5px 30px;
        font-size: 18px;
    } 
       
}

@media(max-width:600px){
    .navbar-admin{
        padding: 5px 15px;
        font-size: 16px;
    } 

}