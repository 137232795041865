.fixed-navbar{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
    /* margin-bottom: 500px; */
}

@keyframes colorChange {
    0% {
        color: white;
    }
    100% {
        color: black;
    }
}
.freeDelivery{
    display:flex;
    align-items: center;
    justify-content: center;
    background: #5ebef7;
    font-size: 22px;
    font-weight: 500;
    color:white;
    height: 40px;
   
}

.freeDelivery p{
    animation: colorChange 1s infinite alternate;
}

.navbar{
    display: flex;
    /* justify-content: center; */
    padding: 10px 50px;
    box-shadow: 0 1px 3px -2px black;
    background: black;
    height: 75px;
    align-items: center;
    /* gap:280px; */
    justify-content: space-between ;
}

.nav-logo{
   display: flex;
   flex-direction: column;
   flex:1;
   align-items: center;
   gap:10px; 
   justify-content: center;
   cursor: pointer;
   /* padding-left: 140px; */
   /* margin-top: -16px; */
}

.admin-btn{
    width: 130px;
    height: 45px;
    outline: none;
    border: 1px solid #7a7a7a;
    border-radius: 75px;
    color: black;
    font-size: 20px;
    font-weight: 500;
    background: #5ebef7;
    cursor: pointer;
}
.nav-logo img{
    width: 240px;
}

.nav-logo p{
    color: #5ebef7;
    font-size: 24px;
    font-weight: 600;
    cursor: pointer;
}


.nav-logo h1{
    color:white;
    font-size: 24px;
    font-weight: 600;
    cursor: pointer;
}

.nav-menu{
    display: flex;
    align-items: center;
    justify-content: center;
    list-style: none;
    gap: 45px;
    color: white;
    font-size: 22px;
    font-weight: 500;
    background: black;
    padding-bottom: 10px;
    border-top: 1px solid #5ebef7;
    border-bottom: 1px solid #5ebef7;
    /* padding-inline-start: 0px; */
    padding-left: 0px;
}

.nav-menu-not-open{
    display:none;
}

.category-name-and-icon{
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
}

.category-name-and-icon img{
    width: 40px;
    /* height: 40px;
    border: 5px solid #5ebef7;
    border-radius: 50%; */
}

@keyframes flicker {
    /* 0%, 19.999%, 22%, 62.999%, 64%, 64.999%, 70%, 80%, 90%, 100% {
      opacity: 100;
    } */
    20%, 21.999%, 63%, 63.999%, 65%, 69.999% {
      opacity: 0.4;
    }
  }
  
.sale-img{
    width: 120px;
    animation: flicker 2s infinite;
}

.nav-menu li{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap:3px;
    cursor: pointer;
}

.nav-menu hr{
    border:none;
    width:80%;
    height: 3px;
    border-radius: 10px;
    background: #5ebef7;
    margin:auto;
}

.nav-login-cart{
    flex:1;
    display: flex;
    align-items: center;
    gap: 45px;
    justify-content: flex-end;
    /* margin-right: -100px; */
    /* padding-left: 50px; */
}

.nav-login-cart button{
    width: 130px;
    height: 45px;
    outline: none;
    border: 1px solid #7a7a7a;
    border-radius: 75px;
    color: black;
    font-size: 20px;
    font-weight: 500;
    background: #5ebef7;
    cursor: pointer;
}
.nav-login-cart button:active{
    background: #f3f3f3;
}

.nav-cart {
    cursor: pointer;
}

.nav-cart-count{
    width: 22px;
    height: 22px;
    border-radius: 11px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -35px;
    margin-left: -55px;
    font-size: 14px;
    background: red;
    color: white;
}

.searchBar{
    flex:1;
    height: 60px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    /* background: linear-gradient(45deg, white,#8ad1fa, white,#8ad1fa, white); */
    /* margin-bottom: -65px;
    position: absolute;
    z-index: 1;
    padding-left: 39%; */
}

.searchInputIcon{
    /*  border: 1px solid black; */
    border-radius: 75px;
    direction: rtl;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    background-color: white;
}

.searchInput{
    width:300px;
    height: 20px;
    text-align: right;
    direction: rtl;
    border: none;
    outline: none;
    font-size: 18px;
}

.goSearch{
    cursor: pointer;
    background: black;
    color:white;
    padding: 5px;
    border-radius: 75px;
    width:50px;
}

.account-circle{
    size:10000px;
    color:white;
}
.nav-dropdown{
    display: none;
    /* color: white; */
}

.nav-menu-open{
    display: none;
}

.nav-menu-not-open{
    display: none;
}


@media(max-width:1280px){

    .freeDelivery{
        font-size: 20px;
        font-weight: 500;
    }

    .navbar{
        padding: 10px 30px;
        /* gap:100px; */
    }

    .searchInput{
        
        width:auto;
        /* height: 15px; */
        font-size: 16px;
        /* width:220px;
        height: 15px;
        font-size: 16px; */
        
    }

    .goSearch{
        padding: 0px;
        border-radius: 75px;
        width:50px;
        /* height: 20px; */
    }

    .nav-logo img{
        width: 200px;
    }

    .nav-logo p{
        font-size: 22px;
    }

    .nav-menu{
        gap:35px;
        font-size: 20px;
    }

    .nav-cart {
        height: 30px;
    }

    .nav-login-cart{
        gap: 25px;
        margin-left: 10px;
    }

    .nav-login-cart button{
        width: 100px;
        height: 40px;
        font-size: 16px;
    }

    .nav-cart-count{
        margin-left: -40px;
        font-size: 16px;
    }

    .sale-img{
        width: 100px;
        animation: flicker 2s infinite;
    }
   
}   

@media(max-width:1024px){

    .freeDelivery{
        font-size: 18px;
        font-weight: 500;
    }

    .navbar{
        padding: 6px 15px;
        /* gap:100px; */
    }

    .nav-logo{
        padding-left: 60px;
     }

    .nav-logo img{
        width: 180px;
    }

    .nav-logo p{
        font-size: 20px;
    }

    .nav-menu{
        gap:25px;
        font-size: 18px;
        /* padding-left: 12px; */
    }

     
    .searchInputIcon{
        /*  border: 1px solid black; */
        /* border-radius: 10px; */
        /* width:auto; */
        width:200px;
        height: 30px;
    }
    
    .searchInput{
        
        width:auto;
        /* height: 15px; */
        font-size: 12px;
        /* width:220px;
        height: 15px;
        font-size: 16px; */
        
    }

    .goSearch{
        padding: 0px;
        border-radius: 75px;
        width:50px;
        /* height: 20px; */
    }


    .nav-login-cart{
        gap: 20px;
    }

    .nav-login-cart button{
        width: 70px;
        height: 30px;
        font-size: 14px;
    }

    .nav-cart {
        width: 30px;
    }

    .nav-cart-count{
        width: 18px;
        height: 18px;
        margin-left: -30px;
        font-size: 12px;
    }
    
    .nav-dropdown{
        display: none;
    }
  
}

@media(max-width:768px){

    .freeDelivery{
        font-size: 18px;
        font-weight: 400;
        height: 40px;
    
    }

    .navbar{
        padding: 10px 0px;
        display: flex;
        justify-content:space-between;
        gap: 75px;
        align-items: center;
        height: 40px;
            
    }

    .nav-dropdown{
        display: block;
        height: 30px;
        width: 50px;
        color:white;
        cursor: pointer;
        margin: 5px;
    }

    .nav-menu{
        display:none;
    }

    .nav-logo{
        padding-left: 0px;
        /* width: 200px; */
     }

    .nav-logo img{
        width: 160px;
    }

    .nav-logo p{
        font-size: 20px;
    }

    .nav-login-cart{
        transform:scale(0.8);
        padding:1px;
        margin-left: 0px;
    }

    .avatar-logout{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
    }

    
    .avatar-logout p{
        color: white;
        font-size: 16px;
    }

    .nav-logo{
        /* padding-right: 20px; */
        padding-left: 0px;
        padding-right: 0px;
    }

    .dropDown-and-menu{
        display:flex;
        flex-direction: column;
    }

    .nav-menu-not-open{
        display: flex;
        flex-direction: column;
        align-items: right;
        /* justify-content: center; */
        direction: rtl;
        /* flex-wrap: wrap; */
        position: fixed;
        top: 0;
        right: -300px; /* Start off-screen */
        width: 250px;
        height: 100%;
        background-color: white;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        transition: right 0.3s ease-out; /* Animation for sliding in/out */
        z-index: 1000;
    }

    .nav-menu-not-open.open {
        right:0;
        /* display: flex; */
        /* justify-content: center; */
    }

    ul {
        list-style: none;
        padding: 0;
        display: flex;
        flex-direction: column-reverse;
        font-weight: 600;
        font-size: 15px;
    }
      
    li {
        padding: 10px;
        border-top: 1px solid grey;
        cursor: pointer;
    }
    
    .close-button {
        /* position: absolute; */
        /* top: 0px;
        left: 0px; */
        background: black;
        color: white;
        height: 100%;
        border: none;
        font-size: 20px;
        padding-top: 5px;
        cursor: pointer;
       
    }

    .headline-closeIcon{
        margin-top: -80px;
        background: black;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .headline{
        color:#5ebef7;
        display: flex;
        padding-right: 10px;;
        align-items: center; 
        justify-content: center;
    }

    .searchBar{
        background: black;
       }
    
    .searchInputIcon{
        height: 40px;
        width: 100%;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 5px;
 
    }

    .searchInput{
        width:95%;
        height: 30px;
        font-size: 16px;
        margin-right:5px;
        border: 1px solid #5ebef7;
        border-radius: 5px;
         
    }

    .goSearch{
        padding:2px;
        height: 30px;
        width:50px;
    } 

}


@media (max-width:570px){
    .nav-logo img{
        width: 140px;
    }

    
    .navbar{
        padding: 10px 0px;
        display: flex;
        justify-content:space-between;
        gap: 50px;
        align-items: center;
        height: 40px;    
    }

    .nav-dropdown{
        display: block;
        height: 30px;
        width: 50px;
        color:white;
        cursor: pointer;
        margin: 5px;
    }

    /* .searchBar{
        padding-left: 10px;
    }
    
    .searchInputIcon{
        height: 15px;
        width: 120px;
    }
    .searchInput{
        width:100px;
        height: 15px;
        font-size: 10px;
         
    }

    .goSearch{
        padding:2px;
        height: 12px;
        width:20px;
    } */
}
@media (max-width:500px){
    .freeDelivery{
        display:flex;
        align-items: center;
        justify-content: center;
        background: #5ebef7;
        font-size: 16px;
        font-weight: 500;
        color:white;
        height: 40px;
    }
    
    /* .searchBar{
        padding-left: 0px;
    }
    
    .searchInputIcon{
        height: 15px;
        width: 40px;
        background: none;
    }
    .searchInput{
        display:none;
        
    }

    .goSearch{
        padding:5px;
        height: 45px;
        width:45px;
    } */

    .nav-logo img{
        width: 140px;
    }


}
