.listproduct {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  width: 100%;
  height: 740px;
  padding: 10px 10px;
  margin: 20px;
  border-radius: 6px;
  background: #fff;
  direction:rtl;
} 

p{
  /* background: yellow; */
  text-align: center;
}

h1{
  /* text-decoration: underline; */
  margin-bottom: 30px;
  text-align: center;
  letter-spacing: 2px;
 }

/* .product-name{
  text-align: right;
} */

.listproduct-format-main{
  display: grid;
  grid-template-columns: 1fr 3fr 1fr 1fr 1fr 1fr;
  gap: 30px;
  width: 100%;
  padding: 20px 0px;
  color: #454545;
  font-size: 20px;
  font-weight: 600;
  align-items: center;
  border-bottom: 1px solid #5ebef7;
}

.listproduct-format{
  align-items: center;
  font-weight: 500;
}
.listproduct-product-icon{
    height: 120px;
    width: 120px;
    object-fit:cover;
}
.listproduct-remove-icon{
  cursor: pointer;
  margin: auto;
  /* background: red; */
}
.listproduct-allproducts{
    overflow-y: auto;
    width: 99.3%
}
@media(max-width:800px){
  .listproduct {
    box-sizing: border-box;
    width: 95%;
    height: 100%;
    padding: 10px 30px;
    margin: 20px auto;
  }
  .listproduct-format-main{
    padding: 15px 0px;
    color: #454545;
    font-size: 12px;
  }
  .listproduct-product-icon{
    height: 60px;
}
}


