
.loginForm {
    height: 100vh;
    display: flex;
    justify-content: center !important;
    align-items: center;
    background-image: url('../../components/Assets/1pexels-sarah-o-shea-98049248-9954246.jpg');
    /* background-position: center; */
    background-repeat: none;
    background-size: cover;
    filter: brightness(70%);
    /* margin-top: 160px; */
}

.loginContainer {
    margin-top: -120px;
    color:white;
    display: flex;
    flex-direction: column;
    gap: 5%;
    width: 550px !important;
    height: 60%;
    box-shadow: 1px 0px 58px 20px rgb(91, 89, 89);
    border-radius: 10px;
    border: 1px solid rgb(26, 22, 22);
    background-color: rgba(0, 0, 0, 0.35);
    backdrop-filter: blur(3px);
    z-index: 1;
    animation: logInHeight .5s forwards;

}
.signUp{
    animation: signUpHeight .5s forwards;
}
@keyframes signUpHeight {
    0% {
        height: 40%;
        }
    100% {
        height: 80%;
        }
}
@keyframes logInHeight {
    0% {
        height: 84%;
        }
    100% {
        height: 62%;
        }
}


.loginLabelDiv {
    display: flex;
    justify-content: center;
    align-items: center;
}

.loginLabel {
    font-family: 'Courier New', Courier, monospace !important;
    margin: 15px !important;
    border-bottom: 2px solid rgb(0, 0, 0);
    /* color: #2c9ad1; */
    /* background: black; */
}

.textfieldDiv {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    width: 100%;
    color: #5ebef7;
}

.textfield {
    border: none;
    width: 70%;
    
}
.formBtn{
    margin-top: 2%;
    padding: 10px;
    cursor: pointer;
    width: 70%;
}
.btnDiv{
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    gap: 15px;
    margin: 15px;
}
.hr{
    width: 70%;
}

@media (max-width:786px){
    .loginForm {
        margin-top: 160px;
    }
}

