/* Overlay for the popup */
.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  /* Popup container */
  .popup-container {
    background: #fff;
    border: 3px solid #5ebef7;
    border-radius: 8px;
    padding: 20px;
    max-width: 600px;
    height: 80%;
    text-align: center;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  /* Popup title */
  .popup-title {
    margin-bottom: 10px;
    font-size: 1.5rem;
    font-weight: bold;
    color: #333;
  }

  .popup-h2{
    color: #5ebef7;
  }

 
  /* Popup text */
  .popup-text {
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 18px;
    color: #555;
  }
  
  /* Confirmation message */
  .popup-confirmation-message {
    margin-bottom: 20px;
    font-size: 1rem;
    color: green;
    font-weight: bold;
  }
  
  /* Button container */
  .popup-button-container {
    display: flex;
    justify-content: space-between;
    gap: 10px;
  }
  
  /* General button styles */
  .popup-button {
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 1rem;
    cursor: pointer;
  }
  
  /* Close button styles */
  .popup-button-close {
    background: #ccc;
    color: #333;
  }
  
  /* Send button styles */
  .popup-button-send {
    background: #5ebef7;
    color: #fff;
  }

  /* Medium screen sizes (tablets) */
@media (max-width: 768px) {
  .popup-container {
    max-width: 90%;
    height: 70%;
    padding: 15px;
  }

  .popup-title {
    font-size: 1.25rem;
  }

  .popup-text {
    font-size: 16px;
  }

  .popup-button {
    font-size: 0.9rem;
    padding: 8px 16px;
  }
}

/* Small screen sizes (phones) */
@media (max-width: 480px) {
  .popup-container {
    max-width: 90%;
    height: auto;
    padding: 10px;
  }

  .popup-title {
    font-size: 1.2rem;
  }

  .popup-text {
    font-size: 16px;
  }

  .popup-button-container {
    flex-direction: column;
    align-items: center;
    gap: 5px;
  }

  .popup-button {
    font-size: 0.8rem;
    padding: 6px 12px;
    width: 100%;
  }
}
  