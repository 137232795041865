.productdisplay{
    display: flex;
    margin: 0px auto;
    box-shadow: 2px 2px 4px -2px #909090;
    border: none;
    border-radius: 10px;
    background: linear-gradient(40deg, #81c5ec, white 30%);
    width: 70%;
    justify-content: space-between;
    border:1px solid #5ebef7; 
    border-radius: 10px; 
    /* height: fit-content; */
    /* margin-bottom: 50px; */

}

/* .productdisplay-left{
    display: flex;
    flex-direction: column;
} */


/* .productdisplay-img-list{
    display: flex;
    flex-direction: column;
    gap: 16px;
} */

/* .productdisplay-img-list img{
    height: 163px;
} */

.black{
    background: black;
    width: 5px;
}

.blue{
    background: #5ebef7;
    width:5px;
    transform: rotate(5deg);
}

.blue1{
    background: #5ebef7;
    width:5px;
    transform: rotate(-5deg);
}
.productdisplay-right{
    display: flex;
    justify-content: center;
    align-items: center; 
    margin: auto;
    width: 50%;
    flex-direction: column;
}

.productdisplay-img{
  width: 400px;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: 1px solid #ddd; */
  background-color: white;
  overflow: hidden;
}

.productdisplay-main-img{
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
}


.productdisplay-left{
    margin: 0px 150px;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    /* direction: rtl; */
    text-align: center;
    margin-top: 30px;   
    max-height: 60%;
}

.productdisplay-left h1{
    color: #3d3d3d;
    font-size: 28px;
    font-weight: 600;
}
.productdisplay-left-stars{
    display: flex;
    align-items: center;
    margin-top: 13px;
    gap: 5px;
    color: #1c1c1c;
    font-size: 16px;
}
.productdisplay-left-prices{
    display: flex;
    align-items: center;
    margin: 40px 0px;
    gap: 30px;
    justify-content: center;
    /* font-size: 34px;
    font-weight: 500; */
}
.productdisplay-left-price-old{
    color: #818181;
    text-decoration: line-through;
    font-size: 24px;
    font-weight: 500;
}
.productdisplay-left-price-new{
    color: #ff4141;
    font-size: 60px;;
    font-weight: 600;
}

.product-information{
    display: flex;
    flex-direction: column;
    /* border: 1px solid #d0d0d0;
    border-radius: 10px; */
    align-items: right;
    gap: 5px;
    margin: 10px 0px 20px 0px;
    border: 1px solid #d0d0d0;
    border-radius: 10px;
    padding: 10px;
    /* background: yellow; */
    background: linear-gradient(170deg, #81c5ec, white 30%);
    box-shadow: 4px 4px 6px -4px #909090;
    max-width: 300px;;
    

}

.product-information h3 {
    text-align: center;
    color: black;
    background: yellow;
    border-radius: 10px;
    width: 50%;
    margin: auto;
}


.container {
    position: relative;
    width: 100%; /* Adjust the width as needed */
    height: 160px; /* Adjust the height as needed */
    overflow: hidden;
    margin-top: 40px;
  }

.logo{
    position: absolute;
    width: 160px; /* Adjust the size as needed */
    height: auto;
    animation: moveTruck 5s linear infinite;
    margin: 70px;
}

@keyframes moveTruck {
    0% {
      left: -50px; /* Start position (adjust as needed) */
    }
    100% {
      left: 100%; /* End position (adjust as needed) */
    }
  }
/* .productdisplay-left-description{
    /* width:800px;
    font-size: large;
} */
.productdisplay-left button{
    margin-top: 20px;
    padding: 10px 10px;
    width: 300px;
    height: 50px;
    font-size: 20px;
    font-weight: 600;
    color: black;
    background: #5ebef7;
    margin-bottom: 25px;
    border: none;
    border-radius: 30px;
    outline: none;
    cursor: pointer;
    /* height:50px; */
}
.productdisplay-left-category{
    margin-top: 10px;
    font-size: large;
}
.productdisplay-left-category span{
    font-weight: 600;
}

@media(max-width:1280px){

    /* .productdisplay-main-img{
        width: 500px;
        height: 486px; 
        width:100%;
    } */
    .productdisplay-left{
        margin: 0px 70px;
        display: flex;
        flex-direction: column;
        /* justify-content: center; */
        /* direction: rtl; */
        text-align: right;
    }
    .productdisplay-left h1{
        font-size: 24px;
    }
    .productdisplay-left-stars{
        font-size: 14px;
    }
    .productdisplay-left-prices{
        margin: 35px 0px;
        gap: 25px;
     }
    .productdisplay-left-price-old{
        font-size: 22px;
    }
    .productdisplay-left-price-new{
        font-size: 32px;;
    }
  
    .productdisplay-left button{
        margin-top: 15px;
        padding: 15px 30px;
        width: 300px;
        font-size: 20px;
        margin-bottom: 25px;
    }
    .productdisplay-left-category{
        margin-top: 10px;
    }
    .productdisplay-left-category span{
        font-weight: 600;
    }
}
@media(max-width:1024px){
    /* .productdisplay-main-img{
        width: 300px;
        height: 386px;
    } */
    .productdisplay-left{
        margin: 0px 70px;
        display: flex;
        flex-direction: column;
        /* justify-content: center; */
        /* direction: rtl; */
        text-align: right;
        padding: 30px 0px;
    }
    .productdisplay-left h1{
        font-size: 20px;
    }
    .productdisplay-left-stars{
        font-size: 10px;
    }
    .productdisplay-left-prices{
        margin: 30px 0px;
        gap: 20px;
     }
    .productdisplay-left-price-old{
        font-size: 20px;
    }
    .productdisplay-left-price-new{
        font-size: 30px;;
    }
  
    .productdisplay-left button{
        margin-top: 12px;
        padding: 12px 26px;
        width: 300px;
        font-size: 20px;
        margin-bottom: 20px;
    }
    .productdisplay-left-category{
        margin-top: 10px;
    }
    .productdisplay-left-category span{
        font-weight: 600;
    }
}
@media(max-width:768px){
    .productdisplay{
        /* gap:10px; */
        justify-content: space-around;
        width: 95%;
    }

    .productdisplay-img{
        width: 200px;
        height: 200px;
    }
    .productdisplay-main-img{
        width: 200px;
        /* height: 246px; */
        /* padding-top: 40px; */
    }
    .productdisplay-left{
        margin: 0px 0px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        /* direction: rtl; */
        /* text-align: right; */
        padding: 50px 0px;
    }
    .productdisplay-left h1{
        font-size: 14px;
    }
    .productdisplay-left-stars{
        font-size: 10px;
    }
    .productdisplay-left-stars img{
        width: 18px;
    }
    .productdisplay-left-prices{
        margin: 30px 0px;
        gap: 15px;
     }
    .productdisplay-left-price-old{
        font-size: 16px;
    }
    .productdisplay-left-price-new{
        font-size: 26px;;
    }
  
    .productdisplay-left button{
        margin-top: 8px;
        padding: 10px 20px;
        width: 300px;
        font-size: 18px;
        margin-bottom: 20px;
        width:auto
    }
    .productdisplay-left-category{
        margin-top: 10px;
    }
    .productdisplay-left-category span{
        font-weight: 600;
    }
}
@media(max-width:500px){
    .productdisplay{
        /* gap:10px; */
        /* justify-content: center; */
        align-items: center;
        flex-direction: column-reverse;
        margin: auto;
        /* border: none;
        background: none;
        box-shadow: none; */
    }

    .productdisplay-right{
        margin: 0px;
        margin-right: 0px;
    }

    .productdisplay-main-img{
        width: auto;
        height: 246px;
        margin: 0;
        padding-top: 0px;
    }
    .productdisplay-left{
        margin: 0px 0px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        /* direction: rtl; */
        text-align: right;
        padding: 50px 0px;
    }
    .productdisplay-left h1{
        font-size: 20px;
        /* margin-right: 30px; */
    }
    .productdisplay-left-stars{
        font-size: 14px;
    }
    .productdisplay-left-stars img{
        width: 16px;
    }
    .productdisplay-left-prices{
        margin: 30px 0px;
        gap: 25px;
     }
    .productdisplay-left-price-old{
        font-size: 20px;
    }
    .productdisplay-left-price-new{
        font-size: 34px;
    }
  
    .productdisplay-left button{
        margin-top: 8px;
        padding: 10px 20px;
        width: 280px;
        font-size: 18px;
        margin-bottom: 20px;
        /* width:auto */
    }
    .productdisplay-left-category{
        margin-top: 10px;
    }
    .productdisplay-left-category span{
        font-weight: 600;
    } 
}
