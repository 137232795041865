.addproduct {
    box-sizing: border-box;
    width: 100%;
    max-width: 800px;
    padding: 30px 50px;
    margin: 20px 30px;
    border-radius: 6px;
    background: #fff;
  }

  .category-item p{
    text-align: right;
    padding-right: 5px;
  }
  
  .image-item p{
    text-align: right;
    padding-right: 5px;
  }
  .addproduct-itemfield {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 100%;
    color: #7b7b7b;
    font-size: 16px;
    margin-bottom: 20px;
    font-size: 20px;
    font-weight: 600;
  }
  
  .addproduct-itemfield input {
    box-sizing: border-box;
    width: 100%;
    height: 50px;
    border-radius: 4px;
    padding-left: 15px;
    border: 1px solid #c3c3c3;
    outline: none;
    color: black;
    font-family: Poppins;
    font-size: 14px;
  }
  
  .addproduct-price {
    display: flex;
    gap: 40px;
  }
  
  .add-product-selector {
    padding: 10px 10px;
    width: 140px;
    height: 50px;
    font-size: 14px;
    color: #7b7b7b;
    border: 1px solid #7b7b7b8d;
    border-radius: 4px;
  }
  
  .addproduct-itemfield div {
    display: flex;
    width: 25%;
  }
  
  .addproduct-thumbnail-img {
    height: 160px;
    width: 160px;
    border-radius: 10px;
    object-fit: contain;
    cursor: pointer;
  }
  
  .addproduct-itemfield div img {
    margin-left: -30px;
  }
  
  .addproduct-btn {
    margin-top: 40px;
    width: 160px;
    height: 50px;
    border-radius: 6px;
    background: #488de7;
    border: none;
    cursor: pointer;
    color: #FFF;
    font-size: 20px;
    font-weight: 500;
  }

  .category-subcategory-information{
    display: flex;
  }

  .information{
    height: 100px;
    text-align: right;
    direction: rtl;
  }
  .special-radio{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .special-radio p{
    text-align: right;
    direction: rtl;
  }

  .actions-buttons{
    display: flex;
    justify-content: space-between;
  }

  .cancel-btn{
    margin-top: 40px;
    width: 160px;
    height: 50px;
    border: 2px solid #488de7;
    border-radius: 6px;
    background: white;
    cursor: pointer;
    color: #488de7;
    font-size: 20px;
    font-weight: 500;
  }

  .cancel-btn:hover{
    background: red;
    color: black;
  }
  @media(max-width:800px) {
    .addproduct {
      width: auto;
      padding: 30px;
      margin: 20px 20px;
    }
  }