.cartitems{
    margin: 100px 170px;
    direction: rtl ;
}
.main-splitter{
    height: 3px;
    background: #e2e2e2;
    border: 0;
    width:100%;
}
.cartitems-format-main{
    display: grid;
    grid-template-columns: 0.5fr 2fr 1fr 2fr 1fr 1fr;
    align-items: center;
    justify-content: center;
    gap: 100px;
    padding: 20px 0px;
    color: #454545;
    /* font-size: 18px; */
    font-weight: 600;
    margin-right:5px;
    font-size: 24px;
    border-top: 3px solid #e2e2e2;
    margin: auto;
}
.cartitems-format{
    font-size: 20px;
    font-weight: 500;
    /* margin:auto; */
    border: none;
}

.cartitems-item-name{
    width: 200px;
    max-width: 200px;
}


.carticon-product-icon{
    height: 100px;
    /* width: 120px; */
    object-fit: contain;
    /* margin-right: 62px; */
    padding: 5px;
    
}
/* .cartitems-remove-icon{
    width: 64px;
    margin: 0px 40px;
    cursor: pointer;
    background: red;
    padding: 10px;
    font-weight: 600; 
} */
.cartitems-quantity{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    /* width: 100px; */
    /* height: 50px; */
    /* border: 2px solid #ebebeb; */
    background: #fff;
    direction: ltr;
}

.cartitems-down{
    display: flex;
    margin: 100px 0px;
    gap:20px;
    border: 1px solid gray;
    border-radius: 10px;
    padding: 20px;
    background: linear-gradient(45deg, #81c5ec, white 20%);
    height: 1200px;
    /* width:fit-content; */
}

.cartitems-total{
    /* flex: 1; */
    display: flex;
    /* display: flex; */
    flex-direction: column;
    /* margin-right: 100px; */
    gap: 40px;
    border-radius: 10px;
    border: 1px solid rgb(26, 22, 22);
    padding:20px;
    width: 500px;
    height: 500px;
    font-size: large;
    margin: 0px 35px;
}

.cartitems-total h1{
    /* border-bottom: 1px solid black; */
    padding-bottom: 5px;
}

.cartitems-total-item{
    display: flex;
    justify-content: space-between;
    padding: 15px 0px;
    font-size: large;
}

.cartitems-total button{
    width: 262px;
    height: 58px;
    outline: none;
    border: none;
    /* background: #ff5a5a; */
    background: #5ebef7;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
}


.splitter{
    width: 3px;
    height: inherit;
}

.cartitems-promocode{
    flex: 1;
    font-size: 16px;
    font-weight: 500;
}

.cartitems-promocode p{
    color: #555;
}

.cartitems-promobox{
    width: 504px;
    margin-top: 15px;
    /* padding-left: 20px; */
    height: 58px;
    background: #eaeaea;
}

.cartitems-promobox input{
    border: none;
    outline: none;
    background: transparent;
    font-size: 16px;
    width: 330px;
    height: 50px;
    
}
.cartitems-promobox button{
    width: 170px;
    height: 58px;
    font-size: 16px;
    background: #8bc3e4;
    color: white;
    cursor: pointer;
    border: none;
}


@media(max-width:1670px){

    .cartitems-down{
        display: flex;
        flex-direction: column;
        margin: 100px 0px;
        gap:20px;
        border: 1px solid gray;
        border-radius: 10px;
        padding: 20px;
        background: linear-gradient(45deg, #81c5ec, white 20%);
        height: 1600px;
        /* width:fit-content; */
    }
    .cartitems-total{
        /* flex: 1; */
        display: flex;
        flex-direction: column;
        /* margin-right: 100px; */
        width: 80%;
        gap: 40px;
        border-radius: 10px;
        border: 1px solid rgb(26, 22, 22);
        padding:20px;
        /* width: 500px; */
        height: 500px;
        font-size: large;
        margin: 20px auto ; 
        
    }
    .cartitems-total h1{
        /* border-bottom: 1px solid black; */
        padding-bottom: 5px;
        /* color:#5ebef7; */
    }
    
    
    .main-splitter{
        height: 3px;
        background: #e2e2e2;
        border: 0;
        width:100%;
    }
    
    .cartitems-quantity{
        width: 100px;
        height: 30px;
    }

    .cartitems-format-main{
        gap: 50px;
    }
    .cartitems-promocode{
        /* flex: 1; */
        display: flex;
        width: 100%;
        margin: auto;

        font-size: 16px;
        font-weight: 500;
    }

}

@media (max-width:1280px){
    .cartitems{
        margin: 60px 50px;
    }
    .cartitems-format-main{
        grid-template-columns: 0.5fr 2fr 1fr 2fr 1fr 1fr;
        gap: 80px;
        padding: 15px 0px;
        font-size: 20px;
    }
    .carticon-product-icon{
        height: 80px;
    }
    .cartitems-remove-icon{
        margin: auto;
    }
    .cartitems-quantity{
        width: 40px;
        height: 30px;
    }
    
    .cartitems-down{
        margin: 80px 0px;
        flex-direction: column;
        gap:40px;
        height: inherit;
    }
    
    .cartitems-total{
        margin: auto;
        width: 90%;
        
    }

    .cartitems-total button{
        width: 200px;
        height: 45px;
        font-size: 16px;
    }
    
    /* .cartitems-promocode{
        margin-right: 10px;
    } */
     
    .cartitems-promobox{
        width: auto;
        max-width: 500px;
    }
    
    .cartitems-promobox input{
        width:100%;        
    }
    .cartitems-promobox input::placeholder{
       padding: 10px;       
    }
    .cartitems-promobox button{
        width: 120px;
        margin-left: -125px;
        font-size: 18px;
    }

    .cartitems-promocode{
        /* flex: 1; */
        display: flex;
        
        margin: auto;

        font-size: 16px;
        font-weight: 500;
    }
}

@media (max-width:1048px){
    .cartitems{
        margin: 60px 50px;
    }
    .cartitems-format-main{
        grid-template-columns: 0.5fr 2fr 1fr 2fr 1fr 1fr;
        gap: 80px;
        padding: 15px 0px;
        font-size: 20px;
    }
    .carticon-product-icon{
        height: 80px;
    }
    .cartitems-remove-icon{
        margin: auto;
    }
    .cartitems-quantity{
        width: 40px;
        height: 30px;
    }
    .cartitems-down{
        margin: 80px 0px;
        flex-direction: column;
        gap:80px;
    }
    
    .cartitems-total{
        margin: 10px;
        
    }

    .cartitems-total button{
        width: 200px;
        height: 45px;
        font-size: 16px;
    }
    
    /* .cartitems-promocode{
        margin-right: 10px;
    } */
     
    .cartitems-promobox{
        width: auto;
        max-width: 500px;
    }
    
    .cartitems-promobox input{
        width:100%;        
    }
    .cartitems-promobox input::placeholder{
       padding: 10px;       
    }
    .cartitems-promobox button{
        width: 120px;
        margin-left: -125px;
        font-size: 18px;
    }
}

@media (max-width:768px){
    .cartitems-format-main{
        
        margin-top: 80px;
    }
    /* .cartitems-total{
        border: none;
    } */

    .cartitems{
        margin-top: 160px;
    }
}
@media (max-width:500px){
    .cartitems-format-main{
        display: none;
        grid-template-columns: 0.5fr 3fr 0.5fr;
        gap: 10px;
    }

    .cartitems-format{
        display: grid;
        margin-top: 80px;
    }
    
    .cartitems-down{
        border: none;
        display: flex;
        margin: 100px 0px;
        margin-top: 160px;
        gap:0px;
        border: 1px solid gray;
        border-radius: 10px;
        padding: 20px;
        background: linear-gradient(45deg, #81c5ec, white 20%);
        height: 1800px;
        /* width:fit-content; */
    }
    .cartitems{
        margin: 0px;
        direction: rtl ;
    }

    .cartitems-total h1{
        font-size: 22px;
    }

    .cartitems-total{
        margin: auto;
    }
    .cartitems-promobox{
        width: auto;
        max-width: 700px;
        display: flex;
        flex-direction: column;
        gap:40px;
    }
}