.shopcategory-indexSort{
    display: flex;
    margin: 0px 100px;
    justify-content: space-between;
    align-items: center;
}


.headline{
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* background: #5ebef7; */
}

.headline h1{
    margin-top: 5px;
    color: #171717;
    font-size: 48px;
    font-weight: 600;
    text-align: center;
}

.headline hr{
    width: 250px;
    height: 4px;
    border-radius: 10px;
    background: #5ebef7;
}


.shopcategory-sort{
    padding: 10px 20px;
    border-radius: 40px;
    border: 1px solid #888;
    font-size: 20px;
    margin-top: 10px;
}

.shopcategory-indexSort p span{
    font-weight: 600;
    font-size: 24px;
}

.shopcategory-products{
    margin: 20px 170px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    row-gap: 80px;
    direction: rtl;
}


@media (max-width:1280px){
    
    .shopcategory-indexSort{
        margin: auto;
        width:90%;
        /* justify-content: space-between;
        align-items: center; */
    }
    
    .shopcategory-sort{
        padding: 5px 10px;
        font-size: 16px;
    }
    
    .shopcategory-indexSort p span{
        font-weight: 600;
        font-size: 20px;
    }
    
    .shopcategory-products{
        margin: 10px 130px;
        row-gap: 40px;
    }

    .shopcategory-products .item {
        margin:auto;
    }
    
    .shopcategory-loadmore{
        display:flex;
        justify-content: center;
        align-items: center;
        margin: 100px auto;
        width: 200px;
        height: 60px;
        border-radius: 75px;
        background: #ededed;
        color: #787878;
        font-size: 16px;
        font-weight: 500;
    }
}
@media (max-width:1024px){
     
    .shopcategory-sort{
        padding: 5px 10px;
    }
    
    .shopcategory-products{
        margin: 10px 150px;
    }
}
@media (max-width:768px){
    .headline{
        margin-top: 160px;
    }

    .headline h1{
        font-size: 24px;
    }

    .shopcategory-sort{
        padding: 5px 10px;
    }
    
    .shopcategory-products{
        margin: 10px 80px;
        margin-top: 160px;
    }

    .shopcategory-loadmore{
       
        margin: 60px auto;
        width: 150px;
        height: 40px;
        font-size: 14px;
    }
}
@media (max-width:500px){
    .shopcategory-sort{
        padding: 5px 10px;
    }
    
    .shopcategory-products{
       grid-template-columns: 1fr 1fr;;
    }

    .shopcategory-loadmore{
       
        margin: 60px auto;
        width: 150px;
        height: 40px;
        font-size: 14px;
    }
}