
.quantity{
    display:flex;
    align-items: center;
    justify-content: space-around;
    /* border: 1px solid grey; */
    /* margin-top: 20px; */
    width: 200px;
    height: 50px;
    font-size: 20px;
    font-weight: 600;
    background: #5ebef7;
    margin-bottom: 25px;
    border-radius: 30px;
    font-size: x-large;
    margin: 25px auto ;
    
}

.quantity-value {
    /* border: 1px solid black; */
    /* margin: 20px 0px; */
    padding:10px 30px;
    background: rgb(168, 166, 166);
    
}

.decrement-button {
    padding:8px;
    background: #5ebef7;
    cursor: pointer;
    /* margin: 20px 0px; */
     border-radius: 30px 0px 0px 30px;
  }

.decrement-button:hover{
    background: white;
    color:#47b5f5;
    border: 1px solid #47b5f5;
    /* font-size: xx-large; */
}
  
.increment-button {
    padding:8px;
    background: #5ebef7;
    cursor: pointer;
    /* margin: 20px 0px; */
    border-radius: 0px 30px 30px 0px;
}


.increment-button:hover{
    background: white;
    color:#47b5f5;
    border: 1px solid #47b5f5;
    /* font-size: xx-large; */
}

.addcart-button{
    margin-top: 20px;
    padding: 20px 40px;
    width: 260px;
    /* height: 50px; */
    font-size: 20px;
    font-weight: 600;
    color: black;
    background: #5ebef7;
    margin-bottom: 25px;
    border: none;
    border-radius: 30px;
    outline: none;
    cursor: pointer;
    /* height:50px; */
}

@media (max-width:1520px){
    .addcart-button{
        width: 200px;
        height: 50px;
        padding: 10px 40px;
    }
}

@media (max-width:1280px){
    .addcart-button{
        width: 200px;
        height: 50px;
        padding: 10px 40px;
    }
}

@media (max-width:1024px){
    .addcart-button{
        width: 140px;
        height: 50px;
        padding: 10px 20px;
        font-size: 16px;
        font-weight: 600;
    }

    .quantity{
        width: 140px;
        height: 50px;
        font-size: 20px;
        font-weight: 600;        
    }

    .quantity-value {
        /* border: 1px solid black; */
        /* margin: 20px 0px; */
        padding:10px 18px;
        background: rgb(168, 166, 166);
        
    }
}

@media (max-width:768px){
    .addcart-button{
        width: 100px;
        height: 50px;
        /* padding: 5px 10px; */
        font-size: 14px;
        font-weight: 600;
        margin-top: 0px;
        margin-bottom: 0px;
    }

    .quantity{
        width: 120px;
        height: 40px;
        font-size: 20px;
        font-weight: 600; 
        margin-top: 0px;
        margin-bottom: 0px;       
    }

    .quantity-value {
        /* border: 1px solid black; */
        /* margin: 20px 0px; */
        padding:10px 18px;
        background: rgb(168, 166, 166);
        margin: 0px;
    }

    .decrement-button {
        padding:8px;
        background: #5ebef7; 
        cursor: pointer;
        margin: 0px;
        border-radius: 20px 0px 0px 20px;

    }
    
    .increment-button {
        padding:8px;
        background: #5ebef7;
        cursor: pointer;
        margin: 0px;
        border-radius: 0px 20px 20px 0px;
    }
    

}