.back-to-top {
    position: fixed;
    bottom: 50px;
    right: 10px;
    z-index: 1000;
  }

  .back-to-top p{
    color: #5ebef7;
    font-weight: 600;
    font-size: large;
  }
  
  .back-to-top-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }

  .back-to-top-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #5ebef7;
    color: black;
    border: none;
    border-radius: 50%;
    padding:  8px;
    font-size: 20px;
    cursor: pointer;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease, transform 0.3s ease;
  }
  
  .back-to-top-button:hover {
    background-color: black;
    color:#5ebef7;
    transform: translateY(-3px);
  }
  

  .whatsapp-icon-link{
    /* position: "fixed", */
    /* bottom: "30px", */
    /* right: "30px", */
    background-Color: #25D366;
    color:#fff;
    border: none;
    border-radius: 50%;
    padding: 8px;
    font-size: 20px;
    cursor: pointer;
    box-shadow: 0px 4px 6px rgba(0,0,0,0.1);
    text-decoration: none
    }

/* Responsive Styling */
@media screen and (max-width: 768px) {
    .back-to-top {
      bottom: 20px; /* Adjust spacing for smaller screens */
      right: 10px;
    }
  
    .back-to-top-button,
    .whatsapp-icon-link {
      padding: 6px;
      font-size: 18px; /* Reduce font size */
    }
  
    .whatsapp-icon-link {
      bottom: 60px; /* Adjust to avoid overlap with Back to Top button */
    }
  }
  
  @media screen and (max-width: 480px) {
    .back-to-top {
      bottom: 15px;
      right: 5px;
    }
  
    .back-to-top-button,
    .whatsapp-icon-link {
      padding: 5px;
      font-size: 16px;
    }
  
    .whatsapp-icon-link {
      bottom: 50px;
    }
  }