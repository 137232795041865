.specials{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    margin-bottom: 100px;
}
.specials h1{
    color: #171717;
    font-size: 40px;
    font-weight: 600;
}
.specials hr {
    width: 150px;
    height: 4px;
    border-radius: 10px;
    background: #5ebef7;
 }
 
.all-specials{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin-top: 50px;
    gap: 30px;
}

@media(max-width:1280px){
    .specials{
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 5px;
        margin-bottom: 100px;
    }
    .specials h1{
        color: #171717;
        font-size: 35px;
        font-weight: 600;
    }
    .specials hr {
        width: 130px;
        height: 4px;
        border-radius: 10px;
        background: #5ebef7;
     }
     
    .all-specials{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        margin-top: 50px;
        gap: 25px;
    }
}
@media(max-width:1024px){
    .specials{
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 5px;
        margin-bottom: 100px;
    }
    .specials h1{
        color: #171717;
        font-size: 30px;
        font-weight: 600;
    }
    .specials hr {
        width: 110px;
        height: 3px;
        border-radius: 10px;
        background: #5ebef7;
     }
     
    .all-specials{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        margin-top: 50px;
        gap: 20px;
    }
}
@media(max-width:768px){
    .specials{
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 5px;
        margin-bottom: 100px;
    }
    .specials h1{
        color: #171717;
        font-size: 25px;
        font-weight: 600;
    }
    .specials hr {
        width: 90px;
        height: 3px;
        border-radius: 10px;
        background: #5ebef7;
     }
     
    .all-specials{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        margin-top: 50px;
        gap: 15px;
    }
}
@media(max-width:500px){
    .specials{
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 3px;
        margin-bottom: 70px;
    }
    .specials h1{
        color: #171717;
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 0;
    }
    .specials hr {
        width: 70px;
        height: 2px;
        border-radius: 10px;
        background: #5ebef7;
     }
     
    .all-specials{
        display: grid;
        grid-template-columns: 1fr 1fr;
        margin-top: 20px;
        gap: 10px;
    }
}




